import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #000;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 10rem;
`;
