import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

function ListItemLoader({ height }) {
  return (
    <ContentLoader
      speed={2}
      height={height}
      backgroundColor="#212121"
      foregroundColor="#121212"
      preserveAspectRatio='xMinYMax meet'
      style={{ width: '100%', height: '100%' }}
    >
      <rect x="0" y="0" rx='50%' ry='50%' width='65' height='65' />

      <rect x="185" y='45%' rx="3" ry="3" width='10%' height='15%' />
      <rect x="350" y='45%' rx="3" ry="3" width='20%' height='15%' />
    </ContentLoader>
  );
}

ListItemLoader.defaultProps = {
  height: 100,
};

ListItemLoader.propTypes = {
  height: PropTypes.number,
};

export default ListItemLoader;
