import styled from 'styled-components';

type StyledButtonProps = {
  background: string;
};

export const StyledButton = styled.button<StyledButtonProps>`
  color: #fff;
  border: none;
  cursor: pointer;
  background: ${({ background }) => background};
  height: 36px;
  padding: 0 0.5rem;
  margin-left: 10px;
  border-radius: 8px;
  border: solid 2px #121212;
  outline: none;

  &:hover,
  &:focus-visible {
    background: #121212;
    border: 2px solid #3dafd8;
  }
`;
