import React, { useEffect, useState, FC } from 'react';

import axios from 'axios';
import {
  Logo,
  Value,
  CoinCode,
  CoinTitle,
  HeaderText,
  FlexWrapper,
  LogoHeaderWrapper,
  PercentagesBox,
  MetricsBox,
  ProfileBox,
  ProfileQuote,
  ProfileText,
} from './styles';
import { Container } from '../home/styles';
import Stat from '../../components/Stat';
import useInterval from '../../hooks/interval';
import LoadingScreen from '../../components/LoadingScreen';
import { intlFormat } from '../../helpers/format-number';
import useDocumentTitle from '../../hooks/document-title';
import SocialIcons from '../../components/SocialIcon';
import Chart from '../../components/Highcharts';

import formatCoin from './formatCoin';
import showToast from '../../helpers/show-toast';

function useFetchData(url: string) {
  const [data, setData] = useState<any>({});
  const [code, setCode] = useState('');
  const [check, setCheck] = useState('fetch');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function fetchData() {
      if (check === 'fetch') {
        axios
          .get(url)
          .then(res => {
            console.log('res is: ', res);
            setData(formatCoin(res.data));
          })
          .catch(err => {
            showToast('Rate limit exceeded');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    fetchData();
  }, [check]);

  return {
    data,
    loading,
    refetch: (arg: string, c?: string) => {
      setCheck(arg);
      if (!code && c) setCode(c);
    },
  };
}

function useProfile(url: string) {
  const [profile, setProfile] = useState<any>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(url)
      .then(res => setProfile(res.data.data))
      .catch(err => setError(err.message));
  }, [url]);

  return { profile, error };
}

type CoinInfoProps = {
  match: any;
};

const CoinInfo: FC<CoinInfoProps> = ({ match }) => {
  const coinName = match.params.asset;
  const { data, loading, refetch } = useFetchData(
    `https://api.coingecko.com/api/v3/coins/${coinName}?localization=false&tickers=false`
  );
  const {
    name,
    code,
    price_usd,
    max_supply,
    avatar_url,
    market_cap_usd,
    total_volume_usd,
    circulating_supply,
  } = data;

  const { profile } = useProfile(
    `https://data.messari.io/api/v1/assets/${code?.toLowerCase() || ''}/profile`
  );

  useDocumentTitle(`${name} price, charts, market cap and other metrics | Socrypto`, match.url);

  useInterval(() => refetch('fetch'), 22500);

  useEffect(() => {
    refetch(`update-${Math.random()}`, code);
  }, [code]);

  useInterval(() => {
    if (data) refetch(`update-${Math.random()}`, code);
  }, 10000);

  if (loading) return <LoadingScreen />;

  return (
    <>
      <Container height="50vh">
        <LogoHeaderWrapper>
          <Logo src={avatar_url} />
          <CoinTitle>{name}</CoinTitle>
          <CoinCode>({code.toUpperCase()})</CoinCode>
        </LogoHeaderWrapper>
      </Container>
      <div
        style={{
          margin: '-1.2rem auto 0 auto',
          width: '20rem',
          borderRadius: '10rem',
          backgroundColor: '#121212',
          border: '1px solid #212121',
        }}
      >
        <h1 style={{ color: '#F1F1F1', textAlign: 'center', margin: 0 }}>
          ${intlFormat(price_usd)}
        </h1>
      </div>
      <PercentagesBox>
        {['1h', '24h', '7d', '14d', '30d', '200d', '1y'].map(d => {
          // @ts-ignore
          const val = data[`percent_change_${d}`];
          return (
            <FlexWrapper key={d}>
              <HeaderText>Δ {d}</HeaderText>
              {/* @ts-ignore */}
              <Value percent={val}>{val}</Value>
            </FlexWrapper>
          );
        })}
      </PercentagesBox>
      <MetricsBox>
        <Stat title="Market Cap" value={`$${intlFormat(market_cap_usd)}`} />
        <Stat title="Volume (24h)" value={`$${intlFormat(total_volume_usd)}`} />
        <Stat title="Circulating Supply" value={intlFormat(circulating_supply)} />
        <Stat title="Total Supply" value={intlFormat(max_supply) || 'N/A'} />
      </MetricsBox>
      <Chart coin={data} selectedCurrency={{ usd_value: 1, code: 'USD' }} />
      {profile?.overview && (
        <ProfileBox>
          {profile?.tagline && <ProfileQuote>{profile.tagline}</ProfileQuote>}
          {profile?.overview && <ProfileText dangerouslySetInnerHTML={{ __html: profile.overview }} />}
        </ProfileBox>
      )}
      {profile?.relevant_resources && (
        <SocialIcons resources={profile.relevant_resources} />
      )}
    </>
  );
};

export default CoinInfo;
