import React from 'react';

import { Link } from 'react-router-dom';

import {
  Category,
  HorizontalCard,
  CardImage,
  CardContent,
  CardTitle,
  CardDescription,
} from './styles';

function Cards(props) {
  return (
    <Category>
      {props.data.map(d => (
        <Link to={{ pathname: `${props.urlPrefix}${d.title.replace(/\s/g, '-').toLowerCase()}` }}>
          <HorizontalCard>
            <CardImage src={d.avatar} />
            <CardContent>
              <CardTitle>{d.title}</CardTitle>
              <CardDescription>{d.description}</CardDescription>
            </CardContent>
          </HorizontalCard>
        </Link>
      ))}
    </Category>
  );
}

export default Cards;
