import React from 'react';

import styled from 'styled-components';

import iOSLogo from '../../../assets/get-on-apple.svg';
import AndroidLogo from '../../../assets/get-on-google.svg';
import { AndroidImg } from '../Landing/styles';

const Container = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#111, #222);
`;

type DownloadProps = {
  showMessage: () => void;
};

function Download({ showMessage }: DownloadProps) {
  return (
    <Container>
      <div>
        <AndroidImg style={{ cursor: 'pointer' }} onClick={showMessage} alt="android logo" src={AndroidLogo} />

        <img style={{ cursor: 'pointer' }} onClick={showMessage} alt="ios logo" src={iOSLogo} />
      </div>
    </Container>
  );
}

export default Download;
