import React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DropdownSection, Heading, LinkList } from './Components';

const AcademyDropdownEl = styled.div`
  width: 25rem;
`;

const Flex = styled.div`
  display: flex;
  > div:first-of-type {
    margin-right: 48px;
  }
`;

const MarketDropdown = () => (
  <AcademyDropdownEl>
    <DropdownSection data-first-dropdown-section>
      <div>
        <Heading>API</Heading>
        <p>Retrieve data such as Price, Volume, Market Cap and Exchange data</p>
        <Flex>
          <div>
            <h4>Resources</h4>
            <LinkList>
              <li>
                <Link to={{ pathname: '/api' }}>Documentation</Link>
              </li>
              <li>
                <Link to={{ pathname: '/api' }}>Limits</Link>
              </li>
            </LinkList>
          </div>
        </Flex>
      </div>
    </DropdownSection>
  </AcademyDropdownEl>
);

export default MarketDropdown;
