import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Icon,
  DropdownSection,
  Heading,
  // ComingSoon,
} from './Components';

import SigmaSmallBlue from '../../../assets/sigma/sigma-small-blue.png';
import SocryptoTextBlack from '../../../assets/socrypto/socrypto-text-black.svg';
// import EthereumIcon from '../../../assets/ethereum-icon.png'

const ProductsDropdownEl = styled.div`
  width: 30rem;
`;

// const Logo = styled.div`
//   width: 38px;
//   height: 38px;
//   margin-right: 16px;
//   border-radius: 100%;
//   opacity: 0.6;
//   background-color: ${({ color }) => `var(--${color})`};
// `

const SigmaImg = styled.img`
  width: 38px;
  height: 38px;
  padding: 5px;
  margin-right: 16px;
  border-radius: 24px;
  background-color: #000;
`;

// const ProjectLogo = styled.img`
//   width: 38px;
//   height: 38px;
//   margin-right: 16px;
// `

const SocryptoLogo = styled.img`
  height: 18px;
  transition: transform .2s ease;

  :hover {
    transform: scale(1.05);
  }
`;

const SubProductsList = styled.ul`
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  a {
    color: var(--dark-grey);
  }
`;

const ProductsSection = styled.ul`
  li {
    display: flex;
    cursor: pointer;

    :hover {
      opacity: 0.75;
    }
  }
`;

const WorksWithStripe = styled.div`
 border-top: 2px solid #fff;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacer);
  padding-top: var(--spacer);
}
h3 {
  margin-bottom: 0;
}
`;

function ProductsDropdown() {
  return (
    <ProductsDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <ProductsSection>
          <Link to={{ pathname: '/sigma' }}>
            <li>
              <SigmaImg src={SigmaSmallBlue} />
              <div>
                <Heading marginBottom="0.25rem">Sigma</Heading>
                <p style={{ marginBottom: 0 }}>All-in-one Crypto Portfolio & Market tracker</p>
              </div>
            </li>
          </Link>
          {/* <Link to={{ pathname: '/etherlearn' }}>
            <li>
              <ProjectLogo src={EthereumIcon} />
              <div>
                <Heading marginBottom="0.25rem">
                  EtherLearn
                  <ComingSoon> (Coming soon)</ComingSoon>
                </Heading>
                <p style={{ marginBottom: 0 }}>Learn about Ethereum / Solidity / Smart Contracts</p>
              </div>
            </li>
          </Link> */}
        </ProductsSection>
      </DropdownSection>
      <DropdownSection>
        <SubProductsList>
          <li>
            <Link to={{ pathname: '/' }}>
              <SocryptoLogo src={SocryptoTextBlack} />
            </Link>
          </li>
        </SubProductsList>
        <WorksWithStripe>
          <Heading marginBottom={0}>
            <Link to={{ pathname: '/newsletter' }}>
              <Icon />
              Blockchain Newsletter
            </Link>
          </Heading>
        </WorksWithStripe>
      </DropdownSection>
    </ProductsDropdownEl>
  );
}

export default ProductsDropdown;
