import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, DropdownSection, Heading, HeadingLink, LinkList } from './Components';

const AcademyDropdownEl = styled.div`
  width: 25rem;
`;

const Flex = styled.div`
  display: flex;
  > div:first-of-type {
    margin-right: 48px;
  }
`;

const MarketDropdown = () => (
  <AcademyDropdownEl>
    <DropdownSection data-first-dropdown-section>
      <div>
        <Heading>Market</Heading>
        <p>Cryptocurrency Market Data & Analytics</p>
        <Flex>
          <div>
            <h4>Prices</h4>
            <LinkList>
              <li>
                <Link to={{ pathname: '/assets' }}>Cryptocurrencies</Link>
              </li>
            </LinkList>
          </div>
        </Flex>
      </div>
    </DropdownSection>
    <DropdownSection>
      <ul>
        <HeadingLink marginBottom={0}>
          <Link to={{ pathname: '/watchlist' }}>
            <Icon />
            Watchlist
          </Link>
        </HeadingLink>
      </ul>
    </DropdownSection>
  </AcademyDropdownEl>
);

export default MarketDropdown;
