import React, { memo } from 'react';
import { Flipper } from 'react-flip-toolkit';

import ListItem from './ListItem';
import ExpandedListItem from './ExpandedListItem';
import './styles.css';

import {
  Title,
  AvatarPlaceholder,
  NameWrapper,
  PriceWrapper,
  ListItemGrid,
  ListContent,
  HideLessThan580,
  HideLessThan480,
  HideLessThan675,
  HideLessThan768,
} from './styles';

const listData = [...Array(10).keys()];

function AnimatedList(props) {
  const { data, loaded, focused, setFocused } = props;
  const onClick = index => {
    setFocused(focused === index ? null : index);
  };

  return (
    <ListContent>
      <Flipper
        flipKey={focused}
        spring="gentle"
        staggerConfig={{ card: { reverse: focused !== null, speed: 0.5 } }}
        decisionData={focused}
      >
        <ul className="list">
          <div className="transparentListItem">
            <div className="listItemContent">
              <ListItemGrid>
                <AvatarPlaceholder />
                <NameWrapper>
                  <Title>Name</Title>
                </NameWrapper>
                <PriceWrapper>
                  <Title>Price</Title>
                </PriceWrapper>
                <Title>Δ 1h </Title>
                <HideLessThan480>
                  <Title>Δ 24h</Title>
                </HideLessThan480>
                <HideLessThan580>
                  <Title>Δ 7d </Title>
                </HideLessThan580>
                <HideLessThan675>
                  <Title>Δ 30d </Title>
                </HideLessThan675>
                <HideLessThan768>
                  <Title>Δ 1y </Title>
                </HideLessThan768>
              </ListItemGrid>
            </div>
          </div>
          {loaded
            ? data.map((item, index) => (
                <li key={index}>
                  {index === focused ? (
                    <ExpandedListItem key={item + index.toString()} item={item} index={focused} onClick={onClick} />
                  ) : (
                    <ListItem
                      key={item + index.toString()}
                      index={index}
                      key={Math.random()}
                      item={item}
                      loaded={loaded}
                      onClick={onClick}
                      focused={focused}
                    />
                  )}
                </li>
              ))
            : listData.map((item, index) => (
                <li>
                  <ListItem key={item + index.toString()} index={index} key={Math.random()} item={item} onClick={onClick} />
                </li>
              ))}
        </ul>
      </Flipper>
    </ListContent>
  );
}

AnimatedList.defaultProps = {
  loaded: false,
};

export default memo(AnimatedList, (p, n) => {
  if (p.focused !== n.focused && n.focused && p.data[n.focused].price_usd !== n.data[n.focused].price_usd) {
    return true;
  }
});
