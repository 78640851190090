import styled from 'styled-components';
import space from '../../assets/space-rounded.svg';

export const Container = styled.div`
  background: url(${space}) center bottom / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 55vh;
`;

export const Heading = styled.h3`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.17;
  color: #FFF;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

export const Image = styled.img`
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;

`;

export const Title = styled.h3`
  font-size: 1.6rem;
`;

export const Text = styled.p`
  font-size: 1rem;
`;
