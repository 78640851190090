export default {
  ethereum101: `
## What is Ethereum?

**Ethereum is the foundation for a new era of the internet:**

* An internet controlled by no single entity, whether it be a company or person.
* An internet where payments and money are wired into the roots.
* An internet where users are safe and in control of their data.
* An internet where users aren't descriminated for their beliefs, status or wealth.
* An internet open to **everyone**.


**Launched in 2015, Ethereum is the world’s leading programmable blockchain.**


**Like other blockchains, Ethereum has a native cryptocurrency called Ether (ETH).** ETH is digital money. If you’ve heard of Bitcoin, ETH has many of the same features. It is purely digital, and can be sent to anyone anywhere in the world instantly. The supply of ETH isn’t controlled by any government or company - it is decentralized, and it is scarce. People all over the world use ETH to make payments, as a store of value, or as collateral.

**But unlike other blockchains, Ethereum can do much more. Ethereum is programmable, which means that developers can use it to build new kinds of applications.

These decentralized applications (or “dapps”) gain the benefits of cryptocurrency and blockchain technology. They can be trustworthy, meaning that once they are “uploaded” to Ethereum, they will always run as programmed. They can control digital assets in order to create new kinds of financial applications. They can be decentralized, meaning that no single entity or person controls them.

**Right now, thousands of developers all over the world are building applications on Ethereum, and inventing new kinds of applications, many of which you can use today:**

*  Cryptocurrency wallets that let you make cheap, instant payments with ETH or other assets
*  Financial applications that let you borrow, lend, or invest your digital assets
*  Decentralized markets, that let you trade digital assets, or even trade “predictions” about events in the real world
*  Games where you own in-game assets, and can even make real money
*  And much, much more.


**The Ethereum community is the largest and most active blockchain community in the world.** It includes core protocol developers, cryptoeconomic researchers, cypherpunks, mining organizations, ETH holders, app developers, ordinary users, anarchists, fortune 500 companies, and, as of now, you.

**There is no company or centralized organization that controls Ethereum.** Ethereum is maintained and improved over time by a diverse global community of contributors who work on everything from the core protocol to consumer applications. This website, just like the rest of Ethereum, was built - and continues to be built - by a collection of people working together.

**Welcome to Ethereum.**
`,
  atomicswapsexplained: `
**Atomic swaps will enable people to directly trade one cryptocurrency for another, directly wallet to wallet, without the need for a third party.**

An atomic swap will utilise **smart contracts** to exchange one cryptocurrency for another. Currently this exchange is done via traditional cryptocurrency exchanges or brokers (Such as Binance, Coinbase, Bitmex etc.).

Many issues come from dealing with 3rd party centralized entities, here are a few examples:

*  **They're vulnerable to hacks**: Despite increasing efforts to stay on top of attacks, even the best of exchanges are prone to malicious hacks. This has been seen time and time again ranging from the MtGox collapse back in 2014, to the more recent $40m Binance hack.
*  **Subject to Regulation**: Due to the nature of centralized exchanges being registered in particular countries, exchanges must abide by certain laws and rules. This leads to exchanges requiring to enforce KYC and many other side affects/restrictions such as bans on privacy coins.
*  Remember... **Not your keys - NOT YOUR COINS**.
`,
  prettygoodprivacyexplained: `
## What is it?
**PGP (Pretty Good Privacy) has been an essential tool for data protection and is used widely in the cryptographic world.**

PGP is an encryption program that provides **cryptographic privacy** and authentication for data communication.
Don't let the "Pretty Good" part fool you - developed by well known cryptographer **Phil Zimmermann** in 1991; it's been widely used as an encryption tool and maintains it's solid reputation to this day.

## Why would you want to use it?

Many think that encryption is a tool for criminals to get away with nefarious activities. That is incorrect - In today's digital world encryption is necessary to protect user data from actors with malicious intent. Encryption is used by hospitals, banks, governments, businesses and every day individuals to secure and send private information.

Here are some examples:

*  Hospitals use encryption to keep patient records private.
*  Banks use encryption to protect themselves from hacks.
*  Governments use encryption for communicating and making sure despite any interception between A-B, the contents stays hidden.
*  Storage companies such as Dropbox will tend to encrypt files at rest and in transit to keep your files safe.

**So why should you use it?** As we move towards a digitalized world, you as an individual are surrounded by threats. Whether it be hackers trying to steal your money, governments doing mass surveillance, identity thieves looking for personal documents to commit fraud under your name, or your crazy ex' trying to read your messages; **your** data is extremely saught after.
Even if you do your due diligance and delete certain private files/messages, there's no way to truly know if all those 3rd party resources you're using (Gmail, AWS, Dropbox) aren't storing backups on a server somewhere without your knowledge or control. **Don't assume** - secure it yourself.

## How does it work?

PGP uses a variation of the public key system. Each user has a **public key** that is shared with other users, and a **private key** which is an encryption key that *should* only be known to the owner. Note: If the private key gets compromised in any way you should immediately generate a new key pair and discard (no longer use) the exposed keys.

See the following diagram for a flow of what happens at a top level when encrypting/decrypting via a public key variation such PGP:

<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/PGP_diagram.svg/1000px-PGP_diagram.svg.png' />

## Where can you use PGP?

PGP can be used to authenticate digital certificates and encrypt/decrypt emails, files, texts, directories and disk partitions.
`,
  keystrokelogging: `
# What is it?

Keystroke Logging (often referred to as keylogging) is a (usually) malicious software or hardware installed onto your PC that keeps a record of users keystrokes, including sensitive passwords.

There are two types of main keyloggers:

### Hardware

These can be implemented as a BIOS level firmware or simply by being plugged into a computer via USB or chip. Unlike software loggers, if the hardware keylogger is attached at the machines BIOS level, it has the ability to record data as soon as the user has switched on their PC.

Some tips on preventing a hardware keylogger:

*  You should know your Desktop/Laptop back to front. If you have any suspicions check any ports for USB keys or unknown chips.
*  Don't leave your laptop unattended in places you have suspicions.
*  Most keyloggers (with the exception of more sophisticated ones that can view clipboard/screen) only record keystrokes. When entering sensitive information you can therefore trick any possible keyloggers by entering your sensitive data (eg. password) split up and use your cursor to click elsewhere within the input. A very simplified example: for the password 'ABC', enter 'BC', click at the start of the input, then finish by entering 'A' - in this case the keylogger would record 'BCA'.

### Software

These consist of a piece of software installed onto your PC by a hacker. Can be unintendedly downloaded by the user (via phishing attacks for example) or downloaded remotely via the attacker. `,
};
