import React, { FC, Dispatch } from 'react';
import { ButtonBanner } from '../styles';
import Button from '../../../components/Button';

type ButtonsProps = {
  background?: string;
  currentPage: number;
  setCurrentPage: Dispatch<number>;
};

const Buttons: FC<ButtonsProps> = ({ background, currentPage, setCurrentPage }) => {
  return (
    <ButtonBanner isSingleElement={currentPage === 1}>
      {currentPage > 1 && <Button background={background} title="Previous 100" onClick={() => setCurrentPage(currentPage - 1)} />}
      <Button background={background} title="Next 100" onClick={() => setCurrentPage(currentPage + 1)} />
    </ButtonBanner>
  );
};

export default Buttons;
