import styled from 'styled-components';
import space from '../../assets/space-rounded.svg';

export const Container = styled.div`
  // background: url(${space}) center bottom / cover;
  background-image:
  linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.85)),
  url(${space});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 55vh;
`;

export const Heading = styled.h3`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.17;
  color: #FFF;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const Subheading = styled.h5`
  margin-bottom: 0;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  color: rgba(255, 255, 255);

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const Subtitle = styled.h5`
  margin: 0;
  font-size: 28px;
`;

export const Image = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const MarkDownWrapper = styled.div`
  margin: 5rem;
  color: #F5F5F5;
  line-height: 1.75rem;

  img {
    width: 500px;
  }

  @media (max-width: 768px) {
    img {
      width: 90vw;
    }
    margin: 2rem;
  }

  @media (max-width: 480px) {
    img {
      width: 99vw;
    }
    margin: 1rem;
  }
`;
