import React, { useState, FC, useEffect } from 'react';

import Lottie from 'react-lottie';

import Buttons from './components/Buttons';
import SearchModal from './components/SearchModal';
import { Container } from '../home/styles';
import useWindowWidth from '../../hooks/window-width';
import LiveImg from '../../assets/live.svg';
import Table from '../../components/Table';
import SearchButton from '../../components/SearchButton';
import useInterval from '../../hooks/interval';
import showToast from '../../helpers/show-toast';
import useCoinList from '../../hooks/coin-list';
import { Heading, Subheading, Live, TopButtonsWrapper, BottomButtonsWrapper } from './styles';
import MARKET_COLUMNS from '../../constants/table-columns/market';
import useKeyPress from '../../hooks/key-press';

const spinner = require('../../assets/spinner.json');

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: spinner,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function getHeading(currentPage: number) {
  return currentPage === 1 ? 'Top 100 Cryptocurrencies' : `Top ${(currentPage - 1) * 100} - ${currentPage * 100} Crypto's`;
}

type MarketsProps = {
  match: any;
  currentPage: number;
};

const Markets: FC<MarketsProps> = props => {
  const { match } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, loading, refetch } = useCoinList(currentPage);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  // Keys
  const [isKActive, setIsKActive] = useKeyPress('k');
  const [isMetaActive, setIsMetaActive] = useKeyPress('Meta'); // cmd key on Mac or windows key on Windows
  const [isEscapeActive, setIsEscapeActive] = useKeyPress('Escape');

  useEffect(() => {
    showToast('Refreshing every minute');
  }, []);

  useEffect(() => {
    if (isMetaActive && isKActive && !searchModalOpen) {
      setSearchModalOpen(true);
      setIsKActive(false);
      setIsMetaActive(false);
      setIsEscapeActive(false);
    }
    if (isEscapeActive && searchModalOpen) {
      setSearchModalOpen(false);
    }
  }, [isMetaActive, isKActive, isEscapeActive]);

  useInterval(() => {
    refetch();
  }, 15000);

  if (error) {
    showToast('Unable to fetch (Rate limit exceeded)');
  }

  const windowWidth = useWindowWidth();

  return (
    <>
      <Container height="50vh">
        <Heading>{getHeading(currentPage)}</Heading>
        <Subheading>by Market Capitalization</Subheading>
        <Live src={LiveImg} alt="live" />
      </Container>
      <TopButtonsWrapper>
        <SearchButton
          title="Search"
          isKActive={isKActive}
          isMetaActive={isMetaActive}
          onClick={() => setSearchModalOpen(true)}
          background="linear-gradient(#1F2124, #17181C)"
        />
        <Buttons background="linear-gradient(#1F2124, #17181C)" currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </TopButtonsWrapper>
      {loading ? (
        <Lottie width="50vw" height="50vh" options={defaultOptions} />
      ) : (
        <Table data={data} columns={MARKET_COLUMNS(match.url, null, null, null, windowWidth)} />
      )}
      <BottomButtonsWrapper>
        <Buttons background="linear-gradient(#1F2124, #17181C)" currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </BottomButtonsWrapper>
      <SearchModal isOpen={searchModalOpen} results={['hello', 'world']} setIsModalOpen={setSearchModalOpen} />
    </>
  );
};

export default Markets;
