import React, { FC, Dispatch, useState } from 'react';

import Modal from 'react-modal';

import {
  Form,
  Input,
  Footer,
  Header,
  Content,
  ResetButton,
  SocryptoLogo,
  CancelButton,
  MagnifierLabel,
  NoResultsFound,
  LoadingIndicator,
  ResultsContainer,
} from './SearchModal.styles';
import './SearchModal.css';
import Result from './Result';
import showToast from '../../../../helpers/show-toast';
import useCoinList from '../../../../hooks/coin-list';
import SocryptoTextBlack from '../../../../assets/socrypto/socrypto-text-black.svg';

type SearchModalProps = {
  isOpen: boolean;
  results: string[];
  setIsModalOpen: Dispatch<boolean>;
};

const customStyles = {
  content: {
    top: '50%',
    padding: 0,
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '6px',
    backgroundColor: '#15172a',
    border: '1px solid #212121',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'inset 1px 1px 0 0   hsla(230, 7%, 20%, 0.5), 0 3px 8px 0 #222',
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const SearchModal: FC<SearchModalProps> = ({ isOpen, setIsModalOpen }) => {
  const [searchValue, setSearchValue] = useState('');
  const { data, error, loading } = useCoinList(0, true);

  if (error) showToast('Unable to fetch list');

  function handleSearchOnChange(e: any) {
    setSearchValue(e.target.value);
  }

  const results = data.filter(({ name, code }) =>
    `${name.toLowerCase()} ${code.toLowerCase()}`.includes(searchValue.toLowerCase())
  );

  return (
    <Modal
      isOpen={isOpen}
      // @ts-ignore
      style={customStyles}
      contentLabel="Example Modal"
      shouldFocusAfterRender={false}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <Content>
        <Header className="SocryptoSearch-SearchBar">
          <Form role="search">
            <MagnifierLabel htmlFor="socrypto-input" id="socrypto-label">
              <svg width="20" height="20" className="SocryptoSearch-Search-Icon" viewBox="0 0 20 20">
                <path
                  d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                  stroke="currentColor"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </MagnifierLabel>
            <LoadingIndicator>
              <svg viewBox="0 0 38 38" stroke="currentColor" strokeOpacity=".5">
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth="2">
                    <circle strokeOpacity=".3" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </g>
              </svg>
            </LoadingIndicator>
            <Input
              autoFocus
              type="search"
              maxLength={64}
              id="socrypto-input"
              value={searchValue}
              aria-autocomplete="list"
              placeholder="Search..."
              aria-labelledby="socrypto-label"
              onChange={handleSearchOnChange}
            />
            <ResetButton tabIndex={-1} type="reset" title="Clear the query">
              <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                  d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                  stroke="currentColor"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ResetButton>
          </Form>
          <CancelButton>Cancel</CancelButton>
        </Header>
      </Content>
      {!!searchValue.length && (
        <>
          <>
            {!!searchValue.length && !results.length && (
              <NoResultsFound>No Coins/Tokens found for &quot;{searchValue}&quot;</NoResultsFound>
            )}
          </>

          {!!searchValue.length && (
            <ResultsContainer>
              {results.slice(0, 20).map(result => (
                <Result data={result} />
              ))}
            </ResultsContainer>
          )}
        </>
      )}
      <Footer>
        <SocryptoLogo src={SocryptoTextBlack} />
      </Footer>
    </Modal>
  );
};

export default SearchModal;
