import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// @ts-ignore
import PDF from './bitcoin.pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import useWindowSize from '../../hooks/use-window-size';

import { Container } from './bitcoin-pdf.styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.min.js`;

export default function BitcoinPDF() {
  const [pageCount, setPageCount] = useState(null);
  const { width } = useWindowSize();

  // @ts-ignore
  function onDocumentLoadSuccess({ numPages }) {
    setPageCount(numPages);
  }

  return (
    <Container>
      <Document file={PDF} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(pageCount), (el, index) => (
          <Page width={width} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Container>
  );
}
