import { Root } from './types';
import { CoinData } from '../../types';

export default function formatCoin(data: Root): CoinData {
  return {
    avatar_url: data.image.thumb,
    circulating_supply: data.market_data.circulating_supply.toString(),
    code: data.symbol.toUpperCase(),
    id: data.id,
    last_updated: data.last_updated,
    market_cap_btc: data.market_data.market_cap.btc,
    market_cap_usd: data.market_data.market_cap.usd,
    max_supply: data.market_data.max_supply,
    name: data.name,
    rank: data.market_cap_rank,
    total_volume_usd: data.market_data.total_volume.usd,
    percent_change_1h: data.market_data.price_change_percentage_1h_in_currency.usd.toFixed(2),
    percent_change_1y: data.market_data.price_change_percentage_1y_in_currency.usd.toFixed(2),
    percent_change_7d: data.market_data.price_change_percentage_7d_in_currency.usd.toFixed(2),
    percent_change_14d: data.market_data.price_change_percentage_14d_in_currency.usd.toFixed(2),
    percent_change_24h: data.market_data.price_change_percentage_24h_in_currency.usd.toFixed(2),
    percent_change_30d: data.market_data.price_change_percentage_30d_in_currency.usd.toFixed(2),
    percent_change_200d: data.market_data.price_change_percentage_200d_in_currency.usd.toFixed(2),
    price_btc: data.market_data.current_price.btc,
    price_usd: data.market_data.current_price.usd,
  };
}
