import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Text = styled.h4`
  color: #fff;
  line-height: 1.3;
  font-size: 18px;
  height: 20px;
  min-width: 50px;
  display: inline;
  text-align: left;
  white-space: nowrap;
`;

export const Title = styled.h4`
  color: #fff;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
`;

export const LinkToImg = styled.img`
  width: 20px;
  position: absolute;
`;

export const HoverLink = styled(Link)`
  border-radius: 25%;
  padding: 0.5rem 0;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  -o-transition: background-color 300ms ease-out;
  -ms-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
  outline: none;
  border: 2px solid transparent;

  &:hover,
  &:focus-visible {
    background-color: #222;
  }

  &:focus-visible {
    border: 2px solid #3dafd8;
  }
`;

export const Value = styled.h5.attrs(({ percent }) => ({
  style: {
    color: percent ? (percent >= 0 ? 'limegreen' : 'tomato') : '#FFF',
  },
}))`
  margin: 0;
  font-size: 18px;
  height: 20px;
  min-width: 50px;
  font-weight: bold;
  display: inline;
  text-align: left;
  white-space: nowrap;
`;

export const Avatar = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 2rem;

  @media (max-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
  }

  @media (max-width: 480px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const AvatarPlaceholder = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 2rem;

  @media (max-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
  }

  @media (max-width: 480px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const AvatarExpanded = styled.img`
  width: 5rem;
  height: 5rem;
  margin-right: 0;
  border-radius: 100px;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    width: 4rem;
    height: 4rem;
  }

  @media (max-width: 480px) {
    width: 3rem;
    height: 3rem;
  }
`;

export const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  @media (max-width: 940px) {
    width: 8rem;
    grid-template-columns: 1fr;
  }

  @media (max-width: 480px) {
    width: 4.5rem;
  }
`;

export const Name = styled.h4`
  color: rgb(5, 15, 25);
  font-size: 18px;
  display: inline;
  margin: 0 0.125rem;
  color: #fff;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const PriceWrapper = styled.span`
  width: 12rem;

  @media (max-width: 768px) {
    width: 8.5rem;
  }

  @media (max-width: 480px) {
    width: 6.5rem;
  }
`;

export const ListItemGrid = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 5rem 15.5rem 7.5rem 5rem 5rem 5rem 5rem 5rem;

  @media (max-width: 940px) {
    grid-template-columns: 5rem 8.5rem 7.5rem 5rem 5rem 5rem 5rem 5rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const PriceExpanded = styled.h4`
  margin: 0;
  color: #f5f5f5;
  font-size: 18px;
  height: 20px;
  font-weight: bold;
  display: inline;
  min-width: 200px;
  text-align: center;
`;

export const Code = styled.h4`
  color: #f5f5f5;
  font-size: 18px;
  font-weight: 400;
  display: inline;
  line-height: 1.3;
  margin: 0 0.125rem;

  @media (max-width: 768px) {
    font-weight: bold;
  }
`;

export const ListContent = styled.div`
  width: 92vw;
  margin-top: -9rem;
  margin-bottom: 9rem;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    width: 95vw;
  }

  @media (max-width: 768px) {
    width: 97.5vw;
  }
}
`;

export const HideLessThan480 = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

export const HideLessThan580 = styled.div`
  @media (max-width: 580px) {
    display: none;
  }
`;

export const HideLessThan675 = styled.div`
  @media (max-width: 675px) {
    display: none;
  }
`;
export const HideLessThan768 = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FirstAdditionalDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SecondAdditionalDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const FlexWrapper = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderText = styled.span`
  color: #fff;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold;
`;
