import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  width: 5rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background: ${(props) => (props.selected ? 'linear-gradient(#111, #121212)' : 'linear-gradient(#1F2124, #17181C)')};
`;
const Title = styled.span`
  color: #FFF;
`;

function GroupButton({ title, onClick, selected }) {
  return (
    <Container selected={selected} onClick={onClick}>
      <Title>{title}</Title>
    </Container>
  );
}

export default GroupButton;
