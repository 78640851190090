import React from 'react';

import { Container, Column, Title, Text, Logo, Content, Link } from './styles';

import SocryptoLargeBlue from '../../assets/socrypto/socrypto-large-blue.svg';
import SocryptoLargeBlack from '../../assets/socrypto/socrypto-large-black.svg';

function Footer() {
  const isDark = true;

  return (
    <Container isDark={isDark}>
      <Column>
        <Link isImage to={{ pathname: '/' }}>
          <Logo src={isDark ? SocryptoLargeBlue : SocryptoLargeBlack} />
        </Link>
      </Column>
      <Column>
        <Content>
          <Title isDark={isDark}>Products</Title>
          <Link to={{ pathname: '/sigma' }}>
            <Text isDark={isDark}>Sigma</Text>
          </Link>
          <Link to={{ pathname: '/newsletter' }}>
            <Text isDark={isDark}>Newsletter</Text>
          </Link>
        </Content>
      </Column>
      <Column>
        <Content>
          <Title isDark={isDark}>API</Title>
          <Link to={{ pathname: '/api' }}>
            <Text isDark={isDark}>Documentation</Text>
          </Link>
          <Link to={{ pathname: '/api' }}>
            <Text isDark={isDark}>Limits</Text>
          </Link>
        </Content>
      </Column>
      <Column>
        <Content>
          <Title isDark={isDark}>Social</Title>
          <Link to={{ pathname: 'https://twitter.com/socrypto_io' }} rel="noopener noreferrer" target="_blank">
            <Text isDark={isDark}>Twitter</Text>
          </Link>
        </Content>
      </Column>
    </Container>
  );
}

export default Footer;
