import React, { FC } from 'react';

import { Container, Avatar, Name, Code } from './Result.styles';

type ResultProps = {
  data: any;
};

const Result: FC<ResultProps> = ({ data }) => (
  <Container to={{ pathname: `/assets/${data.id}` }}>
    <Avatar alt="avatar" src={data.avatar_url} />
    <Name>{data.name}</Name>
    <Code>({data.code})</Code>
  </Container>
);

export default Result;
