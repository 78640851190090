import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

import ReactTooltip from 'react-tooltip';

const vals = {
  Whitepaper: 'sticky-note',
  Github: 'github',
  Website: 'link',
  Twitter: 'twitter',
  Reddit: 'reddit',
};

function SocialIcons({ resources }) {
  return (
    <div className="social-container">
      <ul className="social-icons">
        {resources.map(({ name, url }) =>
          Object.keys(vals).includes(name) ? (
            <li key={name}>
              <a data-tip={name} href={url} target="_blank" rel="noopener noreferrer">
                <i className={`fa fa-${vals[name]}`} />
              </a>
            </li>
          ) : null
        )}
      </ul>
      <ReactTooltip />
    </div>
  );
}

SocialIcons.defaultProps = {
  resources: [],
};

SocialIcons.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default SocialIcons;
