import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  width: 100%;
  height: 50px;
  display: flex;
  border: none;
  padding-left: 18px;
  align-items: center;
  background-color: #222;

  outline: none;

  &:hover,
  &:focus-visible {
    background: #121212;
    border: 2px solid #3dafd8;
  }
`;

export const Avatar = styled.img`
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 1rem;
  border-radius: 2rem;
`;

export const Name = styled.span`
  color: #f5f5f5;
  font-size: 18px;
`;

export const Code = styled.span`
  font-size: 18px;
  margin-left: 16px;
  font-weight: bolder;
`;
