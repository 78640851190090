import React from 'react';

import FeatureGraphic from '../../../assets/sigma/feature-graphic.png';
import AndroidLogo from '../../../assets/get-on-google.svg';
import iOSLogo from '../../../assets/get-on-apple.svg';
import CheckImg from '../../../assets/check.svg';

import { Container, Section, Title, Kicker, CheckList, ListItem, Check, Text, Logos, AndroidImg, ScreenShot } from './styles';

type LandingProps = {
  showMessage: () => void;
};

function Landing({ showMessage }: LandingProps) {
  return (
    <Container>
      <Section>
        <Title>
          <i>The</i> all-in-one Crypto Manager
        </Title>
        <Kicker>100% Free.</Kicker>
        <CheckList>
          <ListItem>
            <Check src={CheckImg} />
            <Text>Track your portfolio</Text>
          </ListItem>
          <ListItem>
            <Check src={CheckImg} />
            <Text>Analyze Coin Market Caps & Metrics</Text>
          </ListItem>
          <ListItem>
            <Check src={CheckImg} />
            <Text>Stay up to date on Crypto/Blockchain news</Text>
          </ListItem>
        </CheckList>
        <Logos>
          <AndroidImg style={{ cursor: 'pointer' }} onClick={showMessage} alt="android logo" src={AndroidLogo} />
          <img style={{ cursor: 'pointer' }} onClick={showMessage} alt="ios logo" src={iOSLogo} />
        </Logos>
      </Section>
      <Section>
        <ScreenShot alt="feature graphic" src={FeatureGraphic} />
      </Section>
    </Container>
  );
}

export default Landing;
