import styled from 'styled-components';

export const LogoHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 4rem;
  border-radius: 2rem;
`;

export const CoinTitle = styled.h1`
  color: #fff;
  margin-bottom: 0;
`;

export const CoinCode = styled.h1`
  margin-top: 0;
  font-weight; bold;
  margin-bottom: -4rem;
`;

export const FlexWrapper = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderText = styled.span`
  color: #fff;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold;
`;

export const Value = styled.h5.attrs(({ percent }: { percent: number }) => ({
  style: {
    color: percent ? (percent >= 0 ? 'limegreen' : 'tomato') : '#FFF',
  },
}))`
  margin: 0;
  font-size: 18px;
  height: 20px;
  min-width: 50px;
  font-weight: bold;
  display: inline;
  text-align: left;
  white-space: nowrap;
`;

export const PercentagesBox = styled.div`
  display: grid;
  margin: 1rem 2rem;
  border-radius: 3px;
  background: linear-gradient(#1f2124, #17181c);
  border: 0.5px solid #212121;
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0.5rem 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MetricsBox = styled.div`
  display: grid;
  margin: 0 2rem 1rem 2rem;
  background: linear-gradient(#1f2124, #17181c);
  border: 0.5px solid #212121;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 768px) {
    margin: 0 1rem 1rem 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const ProfileBox = styled.div`
  margin: 2rem 2rem 1rem 2rem;
  background: linear-gradient(#1f2124, #17181c);
  border: 0.5px solid #212121;

  @media (max-width: 768px) {
    margin: 1rem 1rem 1rem 1rem;
  }
`;

export const ProfileQuote = styled.q`
  color: #fff;
  font-weight: bold;
  display: block;
  text-align: center;
  font-size: 1.25rem;
  margin: 2rem 2rem 0 2rem;

  @media (max-width: 768px) {
    margin: 1rem 1rem 0 1rem;
  }
`;

export const ProfileText = styled.p`
  color: #f5f5f5;
  padding: 0 2rem;
  line-height: 1.25rem;
  a {
    color: #fff;
  }
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;
