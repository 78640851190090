import styled from 'styled-components';

type StyledButtonProps = {
  background: string;
};

export const StyledButton = styled.button<StyledButtonProps>`
  flex-direction: row;
  display: flex;
  align-items: center;
  color: #fff;
  border: none;
  cursor: pointer;
  background: ${({ background }) => background};
  height: 36px;
  padding: 0 0.5rem;
  border-radius: 8px;
  border: solid 2px #121212;
  outline: none;

  &:hover,
  &:focus-visible {
    background: #121212;
    border: 2px solid #3dafd8;
  }
`;

export const SearchImg = styled.img`
  height: 65%;
  padding-right: 0.5rem;
`;

type KeyProps = {
  isActive: boolean;
};

export const Key = styled.span<KeyProps>`
  align-items: center;
  background: ${({ isActive }) =>
    isActive ? 'linear-gradient(-26.5deg, #1f2124, #17181c);' : 'linear-gradient(-26.5deg, #17181c, #1f2124);'}
  border-radius: 3px;
  box-shadow: inset 0 -2px 0 0 #111, inset 0 0 1px 1px #222, 0 1px 2px 1px #333;
  color: #969faf;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-right: 0.4em;
  padding-bottom: 2px;
  position: relative;
  top: -1px;
  padding: 0 0.25rem;
`;
