import React from 'react';
import { toast } from 'react-toastify';

import Toast from '../components/Toast';

function showToast(message: string, autoClose = 5000) {
  toast(<Toast message={message} />, { position: toast.POSITION.BOTTOM_RIGHT, autoClose });
}

export default showToast;
