import React from 'react';
import PropTypes from 'prop-types';

import { Container, Logo } from './styles';
import Img from '../../assets/socrypto/socrypto-large-white.svg';

type ToastProps = {
  message: string;
};

function Toast({ message }: ToastProps) {
  return (
    <Container>
      <Logo src={Img} />
      <p>{message}</p>
    </Container>
  );
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Toast;
