import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';

export const Container = styled.div`
  display: grid;
  padding-top: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: ${({ isDark }) => (isDark ? '#111' : '#FFF')};
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Link = styled(RRLink)`
  ${({ isImage }) => isImage && 'outline: none; border: 2px solid transparent; &:focus-visible { border: 2px solid #3dafd8; }'}
`;

export const Column = styled.div`
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  flex-direction: column;

  :nth-child(1) {
    justify-content: center;
  }
`;

export const Content = styled.div`
  width: 100px;
  text-align: left;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ isDark }) => (isDark ? '#C0C0C0' : 'rgb(17, 51, 85)')};
`;

export const Logo = styled.img`
  width: 10rem;

  @media (max-width: 768px) {
    width: 8rem;
  }

  @media (max-width: 480px) {
    width: 6rem;
  }
`;

export const Text = styled.p`
  color: ${({ isDark }) => (isDark ? '#F5F5F5' : 'rgba(17, 51, 83, 0.6)')};
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: color 0.25s ease 0s;
  &:hover {
    text-decoration: underline;
  }
`;
