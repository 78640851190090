import cssVars from 'css-vars-ponyfill';

import React, { FC } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { BarLine, AnimatedNavbarWrapper, HamburgerWrapper, Content } from './App.styles';

import Home from './pages/home';
import Newsletter from './pages/newsletter';
import Markets from './pages/markets';
import CoinInfo from './pages/coin-info';
import Watchlist from './pages/watchlist';
import EtherLearn from './pages/etherlearn';
import Sigma from './pages/sigma';
import BitcoinPDF from './pages/bitcoin-pdf';
import Api from './pages/api';
import Blog from './pages/blog';
import Academy from './pages/academy';
import Article from './pages/academy/article';

import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import AnimatedNavbar from './components/AnimatedNavbar';
import HamburgerNavbar from './components/HamburgerNavbar';
import './index.css';

import useDocumentTitle from './hooks/document-title';
import formatTitle from './helpers/format-title';

cssVars();

function NoMatch({ location }: { location: { pathname: string } }) {
  return (
    <div>
      <h3 style={{ color: 'red' }}>
        No match for
        <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

type AppProps = {
  location: any;
  history: any;
};

const App: FC<AppProps> = props => {
  const { location, history } = props;

  useDocumentTitle(formatTitle(location.pathname), location.pathname);

  return (
    <>
      <ScrollToTop />
      <BarLine />
      <Content>
        <AnimatedNavbarWrapper>
          <AnimatedNavbar duration={300} />
        </AnimatedNavbarWrapper>

        <HamburgerWrapper>
          <BarLine />
          <HamburgerNavbar history={history} />
        </HamburgerWrapper>

        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/sigma" component={Sigma} />
          <Route exact path="/newsletter" component={Newsletter} />
          <Route exact path="/etherlearn" component={EtherLearn} />
          <Route exact path="/api" component={Api} />
          <Route exact path="/assets" component={Markets} />
          <Route exact path="/assets/:asset" component={CoinInfo} />

          <Route exact path="/bitcoin.pdf" component={BitcoinPDF} />
          <Route exact path="/watchlist" component={Watchlist} />
          <Route exact path="/blog" component={Blog} />

          <Route
            exact
            path="/academy/blockchain"
            render={routeProps => (
              <Academy
                {...routeProps}
                genre="blockchain"
                subheader="Learn about blockchain, consensus protocols, blockchain security, cryptocurrencies and more!"
              />
            )}
          />
          <Route path="/academy/blockchain/:article" component={Article} />
          <Route
            exact
            path="/academy/security"
            render={routeProps => (
              <Academy
                {...routeProps}
                genre="security"
                subheader="Learn about cryptography, network and computer security, personal security, and more!"
              />
            )}
          />
          <Route path="/academy/security/:article" component={Article} />

          <Route component={NoMatch} />
        </Switch>
        <Route render={() => <Footer />} />
      </Content>
      <ToastContainer autoClose={3500} />
      <BarLine />
    </>
  );
};

export default withRouter(props => <App {...props} />);
