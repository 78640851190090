import React from 'react';

import {
  Container,
  LeftColumn,
  TextAlignLeft,
  RightColumn,
  Title,
  Kicker,
  Search,
  Button,
} from './styles';

function EmailBanner() {
  return (
    <Container>
      <LeftColumn>
        <TextAlignLeft>
          <Title>Join the Movement</Title>
          <Kicker>News & Insights that matter.</Kicker>
        </TextAlignLeft>
      </LeftColumn>
      <RightColumn name="newsletter" method="post">
        <input type="hidden" name="form-name" value="newsletter" />
        <Search
          type='email'
          name='email'
          autocorrect='off'
          autocapitalize='off'
          spellcheck='false'
          placeholder='Email address'
        />
        <Button type='submit'>Get started</Button>
      </RightColumn>
    </Container>
  );
}

export default EmailBanner;
