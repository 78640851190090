function getButtons(selectedIndex) {
  if (selectedIndex === 0) {
    return [
      {
        type: 'minute',
        count: 15,
        text: '15m',
      },
      {
        type: 'minute',
        count: 30,
        text: '30m',
      },
      {
        type: 'minute',
        count: 45,
        text: '45m',
      },
      {
        type: 'minute',
        count: 60,
        text: '60m',
      },
      {
        type: 'all',
        text: '360m',
      },
    ];
  }

  if (selectedIndex === 1) {
    return [
      {
        type: 'hour',
        count: 12,
        text: '12h',
      },
      {
        type: 'hour',
        count: 24,
        text: '24h',
      },
      {
        type: 'hour',
        count: 48,
        text: '48h',
      },
      {
        type: 'all',
        text: '72h',
      },
    ];
  }

  if (selectedIndex === 2) {
    return [
      {
        type: 'day',
        count: 7,
        text: '7d',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 3,
        text: '3m',
      },
      {
        type: 'month',
        count: 6,
        text: '6m',
      },
      {
        type: 'ytd',
        text: 'YTD',
      },
      // {
      //   type: 'year', count: 1, text: '1y',
      // },
      // {
      //   type: 'year', count: 2, text: '2y',
      // },
      {
        type: 'all',
        text: 'All',
      },
    ];
  }
}

const DARK_MODE = selectedIndex => ({
  colors: [
    '#2b908f',
    '#90ee7e',
    '#f45b5b',
    '#7798BF',
    '#aaeeee',
    '#ff0066',
    '#eeaaee',
    '#55BF3B',
    '#DF5353',
    '#7798BF',
    '#aaeeee',
  ],
  chart: {
    height: 600,
    marginRight: 10,
    backgroundColor: '#16151A',
    plotBackgroundColor: '#16151A',
    plotBorderColor: '#606063',
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: '#B0B0B3',
      },
      marker: {
        lineColor: '#333',
      },
    },
    boxplot: {
      fillColor: 'linear-gradient(#1F2124, #17181C)',
    },
    candlestick: {
      upColor: 'tomato',
      color: 'limegreen',
      lineColor: 'white',
    },
    errorbar: {
      color: 'white',
    },
  },
  xAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3',
      },
    },
    lineColor: '#707073',
    minorGridLineColor: 'linear-gradient(#1F2124, #17181C)',
    tickColor: '#707073',
    title: {
      style: {
        color: '#A0A0A3',
      },
    },
  },
  yAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3',
      },
    },
    lineColor: '#707073',
    minorGridLineColor: 'linear-gradient(#1F2124, #17181C)',
    tickColor: '#707073',
    tickWidth: 1,
    title: {
      style: {
        color: '#A0A0A3',
      },
    },
  },
  rangeSelector: {
    inputEnabled: selectedIndex === 2,
    buttons: getButtons(selectedIndex),
    selected: 24,
    buttonTheme: {
      fill: 'linear-gradient(#1F2124, #17181C)',
      stroke: '#000000',
      style: {
        color: '#CCC',
      },
      states: {
        hover: {
          fill: '#222',
        },
        select: {
          fill: '#000003',
          stroke: '#000000',
          style: {
            color: 'white',
          },
        },
      },
    },
    inputBoxBorderColor: 'linear-gradient(#1F2124, #17181C)',
    inputStyle: {
      backgroundColor: '#333',
      color: 'silver',
    },
    labelStyle: {
      color: 'silver',
    },
  },
  navigator: {
    handles: {
      backgroundColor: '#666',
      borderColor: '#AAA',
    },
    outlineColor: '#CCC',
    maskFill: 'rgba(255,255,255,0.1)',
    series: {
      color: '#7798BF',
      lineColor: '#A6C7ED',
    },
    xAxis: {
      gridLineColor: 'linear-gradient(#1F2124, #17181C)',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      color: '#F0F0F0',
    },
  },
  legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
  background2: '#505053',
  dataLabelsColor: '#B0B0B3',
  textColor: '#C0C0C0',
  contrastTextColor: '#F0F0F3',
  maskColor: 'rgba(255,255,255,0.3)',
  credits: false,
  scrollbar: {
    barBackgroundColor: '#808083',
    barBorderColor: '#808083',
    buttonArrowColor: '#CCC',
    buttonBackgroundColor: '#606063',
    buttonBorderColor: '#606063',
    rifleColor: '#FFF',
    trackBackgroundColor: '#404043',
    trackBorderColor: '#404043',
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: '#E0E0E3',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#606063',
    },
  },
  exporting: false,
});

const LIGHT_MODE = selectedIndex => ({
  colors: [
    '#2b908f',
    '#90ee7e',
    '#f45b5b',
    '#7798BF',
    '#aaeeee',
    '#ff0066',
    '#eeaaee',
    '#55BF3B',
    '#DF5353',
    '#7798BF',
    '#aaeeee',
  ],
  chart: {
    height: 600,
    marginRight: 10,
    backgroundColor: '#FFF',
    plotBackgroundColor: '#FFF',
    plotBorderColor: '#606063',
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: '#B0B0B3',
      },
      marker: {
        lineColor: '#333',
      },
    },
    boxplot: {
      fillColor: '#505053',
    },
    candlestick: {
      upColor: 'tomato',
      color: 'limegreen',
      lineColor: '#222',
    },
    errorbar: {
      color: 'white',
    },
  },
  xAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3',
      },
    },
    lineColor: '#707073',
    minorGridLineColor: '#505053',
    tickColor: '#707073',
    title: {
      style: {
        color: '#A0A0A3',
      },
    },
  },
  yAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3',
      },
    },
    lineColor: '#707073',
    minorGridLineColor: '#505053',
    tickColor: '#707073',
    tickWidth: 1,
    title: {
      style: {
        color: '#A0A0A3',
      },
    },
  },
  rangeSelector: {
    inputEnabled: selectedIndex === 2,
    buttons: getButtons(selectedIndex),
    selected: 24,
    buttonTheme: {
      fill: '#505053',
      stroke: '#000000',
      style: {
        color: '#CCC',
      },
      states: {
        select: {
          fill: '#000003',
          stroke: '#000000',
          style: {
            color: 'white',
          },
        },
      },
    },
    inputBoxBorderColor: '#505053',
    inputStyle: {
      backgroundColor: '#333',
      color: 'silver',
    },
    labelStyle: {
      color: 'silver',
    },
  },
  navigator: {
    handles: {
      backgroundColor: '#666',
      borderColor: '#AAA',
    },
    outlineColor: '#CCC',
    maskFill: 'rgba(255,255,255,0.1)',
    series: {
      color: '#7798BF',
      lineColor: '#A6C7ED',
    },
    xAxis: {
      gridLineColor: '#505053',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      color: '#F0F0F0',
    },
  },
  legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
  background2: '#505053',
  dataLabelsColor: '#B0B0B3',
  textColor: '#C0C0C0',
  contrastTextColor: '#F0F0F3',
  maskColor: 'rgba(255,255,255,0.3)',
  credits: false,
  scrollbar: {
    barBackgroundColor: '#808083',
    barBorderColor: '#808083',
    buttonArrowColor: '#CCC',
    buttonBackgroundColor: '#606063',
    buttonBorderColor: '#606063',
    rifleColor: '#FFF',
    trackBackgroundColor: '#404043',
    trackBorderColor: '#404043',
  },
  legend: {
    enabled: true,
    itemStyle: {
      color: '#E0E0E3',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#606063',
    },
  },
  exporting: false,
});

const getHighchartTheme = (darkMode, selectedIndex) => (darkMode ? DARK_MODE(selectedIndex) : LIGHT_MODE(selectedIndex));

export default getHighchartTheme;
