import React from 'react';

import { Container, Heading } from './styles';

function EtherLearn() {
  return (
    <>
      <Container>
        <Heading>EtherLearn</Heading>
      </Container>
    </>
  );
}

export default EtherLearn;
