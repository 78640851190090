import React, { useEffect, useState, memo, FC } from 'react';

import axios from 'axios';

import Highchart from './Highchart';
import GroupButton from '../GroupButton';
import { CoinData } from '../../types';

const minutePromise = (coin: CoinData) => [
  axios.get(`https://min-api.cryptocompare.com/data/histominute?fsym=${coin.code}&tsym=USD&limit=360`),
];
const hourlyPromise = (coin: CoinData) => [
  axios.get(`https://min-api.cryptocompare.com/data/histohour?fsym=${coin.code}&tsym=USD&limit=72`),
];
const dailyPromise = (coin: CoinData) => [
  axios.get(`https://min-api.cryptocompare.com/data/histoday?fsym=${coin.code}&tsym=USD&limit=2000`),
  axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${coin.code}&tsyms=USD`),
];

const buttonOptions = ['Minute', 'Hourly', 'Daily'];

type SelectedCurrency = {
  usd_value: number;
  code: string;
};

type ChartProps = {
  coin: CoinData;
  darkMode?: boolean;
  selectedCurrency: SelectedCurrency;
};

const Chart: FC<ChartProps> = ({ darkMode, coin, selectedCurrency }) => {
  const [data, setData] = useState([]);
  const [isCandleModeOn, setIsCandleModeOn] = useState(true);
  const [promises, setPromises] = useState(hourlyPromise(coin));
  const [selectedIndex, setSelectedIndex] = useState(1);

  function updateIndex(newIndex: number) {
    if (newIndex === 0) setPromises(minutePromise(coin));
    if (newIndex === 1) setPromises(hourlyPromise(coin));
    if (newIndex === 2) setPromises(dailyPromise(coin));

    setSelectedIndex(newIndex);
  }

  function getData() {
    const timeNow = Math.floor(Date.now());
    const exchangeRate = selectedCurrency.usd_value;

    axios
      .all(promises)
      .then(
        axios.spread((historicData, todaysData) => {
          const formattedHistoricalData = historicData.data.Data.filter(
            (obj: any) =>
              [obj.close * exchangeRate, obj.high * exchangeRate, obj.low * exchangeRate, obj.open * exchangeRate].every(
                metric => metric !== 0
              ) // remove empty data
          ).map((obj: any) =>
            isCandleModeOn
              ? [
                  obj.time * 1000,
                  obj.close * exchangeRate,
                  obj.high * exchangeRate,
                  obj.low * exchangeRate,
                  obj.open * exchangeRate,
                ]
              : [obj.time * 1000, obj.low * exchangeRate]
          );

          if (todaysData) {
            const todaysPrice = todaysData.data.USD * exchangeRate;
            const latestPrice = isCandleModeOn
              ? [timeNow, todaysPrice, todaysPrice, todaysPrice, todaysPrice]
              : [timeNow, todaysPrice];
            formattedHistoricalData.push(latestPrice);
          }

          setData(formattedHistoricalData);
        })
      )
      .catch(err => {});
  }

  useEffect(() => {
    getData();
  }, [selectedIndex]);

  return (
    <>
      <div style={{ marginBottom: '0.5rem' }}>
        {buttonOptions.map((d, i) => (
          <GroupButton key={d} title={d} selected={selectedIndex === i} onClick={() => updateIndex(i)} />
        ))}
      </div>
      <Highchart
        data={data}
        coin={coin}
        darkMode={darkMode}
        selectedIndex={selectedIndex}
        isCandleModeOn={isCandleModeOn}
        selectedCurrency={selectedCurrency}
      />
    </>
  );
};

function areEqual(prevProps: ChartProps, nextProps: ChartProps) {
  // Avoid rerendering caused by the parents price polling
  return prevProps.coin.code === nextProps.coin.code;
}

export default memo(Chart, areEqual);
