import styled from 'styled-components';
import space from '../../assets/space-flat.svg';

type ContainerProps = {
  height: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  flex-direction: column;
  justify-content: center;
  min-height: ${({ height }) => height || '70vh'};

  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.85)),
    url(${space});

  @media (max-width: 768px) {
    min-height: 40vh;
  }
`;

export const Logo = styled.img`
  height: 8rem;

  @media (max-width: 768px) {
    height: 6rem;
    margin-bottom: 4rem;
  }

  @media (max-width: 480px) {
    height: 4.5rem;
  }
`;
