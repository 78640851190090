import React from 'react';

import Cards from '../../components/Cards';
import { titleCase } from '../../helpers/text-helpers';
import {
  Container,
  Heading,
  Subheading,
  Image,
} from './styles';
import StudentsIcon from '../../assets/academy/students-cap.svg';

const mockData = {
  blockchain: [
    {
      title: 'Ethereum 101',
      avatar: 'https://cdn.shortpixel.ai/client/q_glossy,ret_img,w_500,h_300/https://www.newsbtc.com/wp-content/uploads/2018/08/techanalysis-eth4-500x300.jpg',
      description: 'Ethereum has changed the crypto scene as we know it, but what exactly is it? And what can you do with it?',
    },
    {
      title: 'Atomic Swaps Explained',
      avatar: 'https://hackernoon.com/hn-images/1*jpJtLM91rm89dpNFq3w3rA.jpeg',
      description: 'Atomic Swaps has the potential of completely revolutionizing the money transfer system in the crypto space.',
    },
  ],
  security: [
    {
      title: 'Pretty Good Privacy Explained',
      avatar: 'https://www.lifewire.com/thmb/kTTk2aoLsTizS2WQ4ZK92EoJLIA=/768x0/filters:no_upscale():max_bytes(150000):strip_icc()/encrypt_Alengo_getty-5865be8f3df78ce2c3596874.jpg',
      description: 'PGP (Pretty Good Privacy) has been an essential tool for data protection and is used widely in the cryptographic world.',
    },
    {
      title: 'Keystroke Logging',
      avatar: 'https://www.pandasecurity.com/mediacenter/src/uploads/2017/12/pandasecurity-MC-keylogger-HP.jpg',
      description: 'Often referred to as keylogging, find out what they are, how to detect and remove them.',
    },
  ],
};

function Academy(props) {
  const { genre, subheader } = props;
  return (
    <>
      <Container>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Heading>{titleCase(genre)}</Heading>
          <Image src={StudentsIcon} />
        </div>
        <Subheading>{subheader}</Subheading>
      </Container>
      <Cards {...props} data={mockData[genre]} urlPrefix={`/academy/${genre}/`} />
    </>
  );
}

export default Academy;
