import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import getHighchartTheme from '../../helpers/get-highchart-theme';

function Highchart(props) {
  const { data, coin, isCandleModeOn, darkMode = true, selectedIndex, selectedCurrency } = props;

  const options = {
    ...getHighchartTheme(darkMode, selectedIndex),
    series: [
      {
        type: isCandleModeOn ? 'candlestick' : 'line',
        // type: 'line',
        name: `${coin.code}/${selectedCurrency.code}`,
        data,
      },
    ],
  };

  return <HighchartsReact constructorType="stockChart" highcharts={Highcharts} options={options} />;
}

Highchart.propTypes = {
  darkMode: PropTypes.bool,
  coin: PropTypes.object.isRequired,
  isCandleModeOn: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectedCurrency: PropTypes.shape({
    code: PropTypes.string.isRequired,
    usd_value: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

function areEqual(prevProps, nextProps) {
  // Avoid rerendering caused by the parents GroupButton select
  return prevProps.data === nextProps.data;
}

export default memo(Highchart, areEqual);
