import React, { FC, useEffect, useState } from 'react';

import { StyledButton, SearchImg, Key } from './styles';
import searchIcon from '../../assets/markets/search.svg';

type KeyProps = {
  title: string;
  isActive: boolean;
};

const KeyBox: FC<KeyProps> = ({ title, isActive }) => {
  return <Key isActive={isActive}>{title}</Key>;
};

type SearchButtonProps = {
  title: string;
  onClick(): void;
  background?: string;
  isKActive: boolean;
  isMetaActive: boolean;
};

function getOS() {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

const SearchButton: FC<SearchButtonProps> = ({
  onClick,
  background = '#000',
  isKActive,
  isMetaActive,
}) => {
  const [platform, setPlatform] = useState('');

  useEffect(() => {
    setPlatform(getOS());
  }, []);

  return (
    <StyledButton background={background} onClick={onClick}>
      <SearchImg src={searchIcon} />
      <KeyBox isActive={isKActive} title={platform == 'Mac' ? '⌘' : 'CTRL'} />
      <KeyBox isActive={isMetaActive} title="K" />
    </StyledButton>
  );
};

export default SearchButton;
