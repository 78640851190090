import React from 'react';

import {
  Grid,
  Container,
  LargeImg,
  Title,
  Kicker,
  Div1,
  Div2,
  Div3,
  Div4,
  Div5,
  Div6,
  Div7,
  Div8,
  Div9,
  Div10,
  Div11,
  Div12,
  Div13,
  Div14,
  Div15,
} from './styles';

function CoinGrid() {
  return (
    <Container>
      <Title>Track and manage all your coins & tokens</Title>
      <Kicker>With support for over 3000 cryptocurrencies</Kicker>
      <Grid>
        <Div1>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/doge.png" />
        </Div1>
        <Div2>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/nano.png" />
        </Div2>
        <Div3>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/xmr.png" />
        </Div3>
        <Div4>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/link.png" />
        </Div4>
        <Div5>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/xlm.png" />
        </Div5>
        <Div6>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/btc.png" />
        </Div6>
        <Div7>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/xrp.png" />
        </Div7>
        <Div8>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/eth.png" />
        </Div8>
        <Div9>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/zec.png" />
        </Div9>
        <Div10>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/miota.png" />
        </Div10>
        <Div11>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/bat.png" />
        </Div11>
        <Div12>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/dcr.png" />
        </Div12>
        <Div13>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/rep.png" />
        </Div13>
        <Div14>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/mkr.png" />
        </Div14>
        <Div15>
          <LargeImg src="https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/ltc.png" />
        </Div15>
      </Grid>
    </Container>
  );
}

export default CoinGrid;
