import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  padding: 2rem 0 2rem 0;
  background: linear-gradient(#111, #000, #111);
`;

export const Grid = styled.div`
  display: grid;
  margin-top: 4rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;
export const Title = styled.h1`
  margin-bottom: 1.875rem;
  font-size: 2.25rem;
  line-height: 3.0625rem;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

export const Kicker = styled.h3`
  color: #f5f5f5;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export const LargeImg = styled.img`
  width: 3.5rem;

  @media (max-width: 768px) {
    width: 2.5rem;
  }

  @media (max-width: 480px) {
    width: 2rem;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div1 = styled(FlexCenter)`
  grid-area: 2 / 1 / 3 / 2;
`;
export const Div2 = styled(FlexCenter)`
  grid-area: 1 / 2 / 2 / 3;
`;
export const Div3 = styled(FlexCenter)`
  grid-area: 2 / 3 / 3 / 4;
`;
export const Div4 = styled(FlexCenter)`
  grid-area: 1 / 4 / 2 / 5;
`;
export const Div5 = styled(FlexCenter)`
  grid-area: 2 / 5 / 3 / 6;
`;
export const Div6 = styled(FlexCenter)`
  grid-area: 1 / 6 / 2 / 7;
`;
export const Div7 = styled(FlexCenter)`
  grid-area: 2 / 7 / 3 / 8;
`;
export const Div8 = styled(FlexCenter)`
  grid-area: 1 / 8 / 2 / 9;
`;
export const Div9 = styled(FlexCenter)`
  grid-area: 2 / 9 / 3 / 10;
`;
export const Div10 = styled(FlexCenter)`
  grid-area: 1 / 10 / 2 / 11;
`;
export const Div11 = styled(FlexCenter)`
  grid-area: 3 / 10 / 4 / 11;
`;
export const Div12 = styled(FlexCenter)`
  grid-area: 3 / 8 / 4 / 9;
`;
export const Div13 = styled(FlexCenter)`
  grid-area: 3 / 6 / 4 / 7;
`;
export const Div14 = styled(FlexCenter)`
  grid-area: 3 / 4 / 4 / 5;
`;
export const Div15 = styled(FlexCenter)`
  grid-area: 3 / 2 / 4 / 3;
`;
