const PATH_MAP = {
  '/api': 'Professional API plans',
  '/sigma': 'Sigma - Portfolio tracker',
  '/watchlist': 'Watchlist',
  '/assets': 'Cryptocurrency Market Data',
};

function formatTitle(path) {
  return PATH_MAP[path] ? `${PATH_MAP[path]} - Socrypto` : 'Join the Movement - Socrypto';
}

export default formatTitle;
