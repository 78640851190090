import React from 'react';

import { Container, Logo } from '../home/styles';
import EmailBanner from '../../components/EmailBanner';
import SocryptoLogo from '../../assets/socrypto/socrypto-large-white.svg';

function Newsletter() {
  return (
    <>
      <Container height="50vh">
        <Logo src={SocryptoLogo} />
      </Container>
      <EmailBanner />
    </>
  );
}

export default Newsletter;
