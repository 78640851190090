import React, { useState, FC } from 'react';

import axios, { AxiosResponse } from 'axios';
import createPersistedState from 'use-persisted-state';

import { Container } from '../home/styles';
import LiveImg from '../../assets/live.svg';
import Table from '../../components/Table';
import SelectButton from '../../components/SelectButton';
import Buttons from '../markets/components/Buttons';
import { Heading, Live } from '../markets/styles';
import MARKET_COLUMNS from '../../constants/table-columns/market';

import useCoinList from '../../hooks/coin-list';
import useInterval from '../../hooks/interval';
import showToast from '../../helpers/show-toast';
import { CoinData } from '../../types';
import formatCoin from '../coin-info/formatCoin';

const useWatchlistState = createPersistedState('watchlist');
const getUrl = (id: string) =>
  `https://api.coingecko.com/api/v3/coins/${id}?market_data=true&community_data=false&developer_data=false&sparkline=false`;

type WatchlistProps = {
  match: any;
};

type SelectObject = {
  label: string;
  value: CoinData;
};

const Watchlist: FC<WatchlistProps> = props => {
  const { match } = props;
  const { data, error, loading } = useCoinList(0, true);
  const [watchlist, setWatchlist] = useWatchlistState<CoinData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  if (error) showToast('Unable to fetch list');

  function handleAddCoin(coin: SelectObject) {
    setWatchlist(watchlist.concat([coin.value]));
  }

  function handleRemoveCoin(coin: CoinData) {
    setWatchlist(watchlist.filter(d => d.id !== coin.id));
  }

  useInterval(() => {
    const coinURLs: Promise<AxiosResponse<any>>[] = [];

    watchlist.forEach(({ id }) => {
      const promise = axios.get(getUrl(id));
      coinURLs.push(promise);
    });

    axios.all(coinURLs).then(res => {
      setWatchlist(res.map(axiosResponse => formatCoin(axiosResponse.data)));
    });
  }, 10000);

  return (
    <>
      <Container height="50vh">
        <Heading>Watchlist</Heading>
        <div style={{ width: '20rem' }}>
          <SelectButton
            loading={loading}
            onChange={handleAddCoin}
            options={data
              // @ts-ignore
              .filter(coin => !watchlist.map(d => d.id).includes(coin.id))
              .map(coin => ({ label: coin.name, value: coin }))}
          />
        </div>
        <Live src={LiveImg} alt="live" />
      </Container>
      {watchlist.length ? (
        <Table data={watchlist} columns={MARKET_COLUMNS(match.url, watchlist, handleAddCoin, handleRemoveCoin)} />
      ) : (
        <div
          style={{
            display: 'flex',
            height: '10rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1 style={{ fontSize: 18 }}>Get started by adding coins above</h1>
        </div>
      )}
      {watchlist.length >= 100 && (
        <div style={{ marginBottom: -60, marginRight: 10, paddingTop: 10 }}>
          <Buttons currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      )}
    </>
  );
};

export default Watchlist;
