import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 250px;
  width: 100%;

  background: linear-gradient(-45deg, #65C2DE, #23a6d5, #23d5ab, #CB54CB);
  background-size: 400% 400%;
  animation: gradientBG 30s ease infinite;
  
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const TextAlignLeft = styled.div`
  display: inline-block;
  text-align: left;
`;

export const RightColumn = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h1`
  color: #FFF;
  font-size: 40px;
  font-weight: 600;
  margin: 0px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const Kicker = styled.h3`
  color: #FFF;
  margin: 0px;
  font-size: 38px;
  font-weight: 400;
  white-space: nowrap;

  @media (max-width: 1024px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }

`;

export const Search = styled.input`
  margin-right: 5px;
  -webkit-appearance: none;
  font-size: 16px;
  line-height: 1.75;
  color: rgb(11, 52, 102);
  flex: 1 1 0%;
  padding: 13px 16px 15px;
  border-radius: 4px;
  border-style: none;

  @media (max-width: 780px) {
    margin-left: 2rem;
  }

  @media (max-width: 480px) {
    margin-left: 5px;
    margin-bottom: 10px;
  }
`;
export const Button = styled.button`
  width: auto;
  color: #FFF;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  background-color: #111;
  margin-right: 4rem;
  margin-left: 5px;
  border-radius: 4px;
  transition: all 100ms ease-in-out 0s;
  padding: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: #111;
  border-image: initial;

  @media (max-width: 1024px) {
    margin-right: 2rem;
  }

  @media (max-width: 480px) {
    margin-right: 5px;
  }

  &:hover {
    background-color: rgb(0, 165, 93);
    border-color: rgb(0, 165, 93);
  }
`;
