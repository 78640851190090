import React, { memo } from 'react';

import { Flipped } from 'react-flip-toolkit';
import { intlFormat } from '../../helpers/format-number';
import {
  Name,
  Code,
  Value,
  HoverLink,
  LinkToImg,
  HeaderText,
  FlexWrapper,
  PriceExpanded,
  AvatarExpanded,
  FirstAdditionalDiv,
  SecondAdditionalDiv,
} from './styles';
import Stat from '../Stat';
import LinkImg from '../../assets/link-to.png';

function ExpandedListItem({ index, onClick, item }) {
  const { id, code, name, price_usd, market_cap_usd, total_volume_usd, circulating_supply, max_supply } = item;

  return (
    <Flipped
      flipId={`listItem-${index}`}
      stagger="card"
      onStart={el => {
        setTimeout(() => {
          el.classList.add('animated-in');
        }, 400);
      }}
    >
      <div
        tabIndex="0"
        className="expandedListItem"
        onClick={() => onClick(index)}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onClick(index);
          }
        }}
      >
        <Flipped inverseFlipId={`listItem-${index}`}>
          <div className="expandedListItemContent">
            <HoverLink to={{ pathname: `/assets/${id}` }}>
              <Flipped flipId={`avatar-${index}`} stagger="card-content">
                <div style={{ textAlign: 'center' }}>
                  <AvatarExpanded src={`https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/${code.toLowerCase()}.png`} />
                  <LinkToImg src={LinkImg} />
                </div>
              </Flipped>
              <div className="description">
                <Flipped flipId={`description-${index}-name`} stagger="card-content">
                  <span>
                    <Name>{name}</Name>
                    &nbsp;
                    <Code>{code}</Code>
                  </span>
                </Flipped>
                <Flipped flipId={`description-${index}-price_usd`} stagger="card-content">
                  <PriceExpanded>${intlFormat(price_usd)}</PriceExpanded>
                </Flipped>
              </div>
            </HoverLink>
            <div className="additional-content">
              <FirstAdditionalDiv>
                {['1h', '24h', '7d', '14d', '30d', '200d', '1y'].map(d => {
                  const val = item[`percent_change_${d}`];
                  return (
                    <FlexWrapper key={d}>
                      <HeaderText>Δ {d}</HeaderText>
                      <Value percent={val}>{val}%</Value>
                    </FlexWrapper>
                  );
                })}
              </FirstAdditionalDiv>
              <SecondAdditionalDiv>
                <Stat title="Market Cap" value={`$${intlFormat(market_cap_usd)}`} />
                <Stat title="Volume (24h)" value={`$${intlFormat(total_volume_usd)}`} />
                <Stat title="Circulating Supply" value={intlFormat(circulating_supply)} />
                <Stat title="Total Supply" value={intlFormat(max_supply) || 'N/A'} />
              </SecondAdditionalDiv>
            </div>
          </div>
        </Flipped>
      </div>
    </Flipped>
  );
}

function areEqual(prevProps, newProps) {
  // return true
}

export default memo(ExpandedListItem, areEqual);
