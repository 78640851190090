import React, { FC } from 'react';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './custom.css';
// @ts-ignore
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { CoinData } from '../../types';
import { TipContainer, Tip } from './styles';
import 'react-table-hoc-fixed-columns/lib/styles.css';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

function Tips() {
  return (
    <TipContainer>
      <Tip>Tip: Hold shift when sorting to multi-sort!</Tip>
    </TipContainer>
  );
}

type Column = {
  Header?: string;
  accessor?: string;
  fixed?: string;
  width?: number;
};

type TableProps = {
  data: CoinData[];
  columns: Column[];
};

const Table: FC<TableProps> = props => {
  const { data, columns } = props;

  return (
    <>
      <ReactTableFixedColumns
        data={data}
        columns={columns}
        defaultPage={100}
        pageSize={data.length >= 100 ? 100 : data.length}
        showPagination={false}
        className="-striped -highlight -zero-index"
        pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
      />
      <Tips />
    </>
  );
};

export default Table;
