import React from 'react';

import { Container, Logo } from './styles';
import socryptoLogo from '../../assets/socrypto/socrypto-large-white.svg';

function LoadingScreen() {
  return (
    <Container>
      <Logo src={socryptoLogo} />
    </Container>
  );
}

export default LoadingScreen;
