import React, { memo } from 'react';

import { Flipped } from 'react-flip-toolkit';
import { intlFormat } from '../../helpers/format-number';
import { ListItemLoader } from '../ContentLoader';

import {
  Text,
  Avatar,
  NameWrapper,
  Name,
  PriceWrapper,
  ListItemGrid,
  Code,
  HideLessThan580,
  HideLessThan480,
  HideLessThan675,
  HideLessThan768,
  Value,
} from './styles';

const shouldFlip = index => (prev, current) => index === prev || index === current;

function ListItem({ index, onClick, loaded, item }) {
  const {
    name,
    code,
    price_usd,
    percent_change_1h,
    percent_change_24h,
    percent_change_7d,
    percent_change_30d,
    percent_change_1y,
  } = item;

  return (
    <Flipped flipId={`listItem-${index}`} stagger="card" shouldInvert={shouldFlip(index)}>
      <div
        tabIndex="0"
        className="listItem"
        onKeyPress={e => {
          if (e.key === 'Enter') {
            if (loaded) {
              onClick(index);
            }
          }
        }}
        onClick={loaded ? () => onClick(index) : () => {}}
      >
        <Flipped inverseFlipId={`listItem-${index}`}>
          {!loaded ? (
            <div style={{ height: 65, margin: '1rem' }}>
              <ListItemLoader />
            </div>
          ) : (
            <div className="listItemContent">
              <ListItemGrid>
                <Flipped flipId={`avatar-${index}`} stagger="card-content" shouldFlip={shouldFlip(index)}>
                  <Avatar src={`https://sigma-crypto-icons-icon.s3-eu-west-1.amazonaws.com/${item.code.toLowerCase()}.png`} />
                </Flipped>
                <Flipped flipId={`description-${index}-name`} stagger="card-content" shouldFlip={shouldFlip(index)}>
                  <NameWrapper>
                    <Name>{name}&nbsp;</Name>
                    <Code>{code}</Code>
                  </NameWrapper>
                </Flipped>
                <Flipped flipId={`description-${index}-price_usd`} stagger="card-content" shouldFlip={shouldFlip(index)}>
                  <PriceWrapper>
                    <Text>${intlFormat(price_usd)}</Text>
                  </PriceWrapper>
                </Flipped>
                <Value percent={percent_change_1h}>{percent_change_1h}%</Value>
                <HideLessThan480>
                  <Value percent={percent_change_24h}>{percent_change_24h}%</Value>
                </HideLessThan480>
                <HideLessThan580>
                  <Value percent={percent_change_7d}>{percent_change_7d}%</Value>
                </HideLessThan580>
                <HideLessThan675>
                  <Value percent={percent_change_30d}>{percent_change_30d}%</Value>
                </HideLessThan675>
                <HideLessThan768>
                  <Value percent={percent_change_1y}>{percent_change_1y}%</Value>
                </HideLessThan768>
              </ListItemGrid>
            </div>
          )}
        </Flipped>
      </div>
    </Flipped>
  );
}

export default memo(ListItem, () => true); // second argument IS NEEDED to avoid freeze on expand
