import styled from 'styled-components';

export const TipContainer = styled.div`
  width: 100vw;
  padding: 0.25rem;
  text-align: center;
  
  background: linear-gradient(-45deg, #65C2DE, #23a6d5, #23d5ab, #CB54CB);
  background-size: 400% 400%;
  animation: gradientBG 30s ease infinite;
  
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const Tip = styled.em`
  color: #FFF;
  font-weight: bold;
`;
