import styled from 'styled-components';

export const SocryptoLogo = styled.img`
  height: 18px;
  transition: transform .2s ease;

  :hover {
    transform: scale(1.05);
  }
`;

export const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .025em;
  color: #8898aa;
  text-transform: uppercase;
  margin: 1.5rem 0 0.75rem 0;

  :hover {
    color: #8898aa;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomLink = styled.div`
  cursor: pointer;
`;

export const ProductName = styled.span`
  color: var(--teal);
  font-size: 17px;
  font-weight: 500;
  line-height: 40px;
  white-space: nowrap;
`;

export const SigmaImg = styled.img`
  width: 32px;
  height: 32px;
  padding: 5px;
  margin-right: 14px;
  border-radius: 16px;
  background-color: #000;
`;

export const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 13px;
  background-color: var(--black);
  opacity: 0.8;
  display: inline-block;
`;
