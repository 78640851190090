import styled from 'styled-components';

export const Category = styled.ul`
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  margin: -3rem 3rem 3rem 3rem;

  @media (max-width: 768px) {
    margin: -3rem 2rem 3rem 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    margin: -3rem 1rem 3rem 1rem;
    grid-template-columns: 1fr
  }
`;

export const HorizontalCard = styled.li`
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 5px 9px rgba(0,0,0,0.08), 0 5px 9px rgba(0,0,0,0.04);
  transition: all 0.2s linear;

  :hover {
    box-shadow: 0 10px 18px rgba(0,0,0,0.08), 0 10px 18px rgba(0,0,0,0.04);
    transform: translateY(-2px);
  }
  display: flex;
  height: 10rem;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

export const CardImage = styled.img`
  width: 15rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  padding: 1.5rem;
  width: 100%;
  background: linear-gradient(#1F2124, #17181C);
  
  @media (max-width: 768px) {
    min-height: 15rem;
  }
`;

export const CardTitle = styled.h3`
  color: #FFF;
  font-weight: bold;
`;

export const CardDescription = styled.p`
  color: #F5F5F5;
`;
