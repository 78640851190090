import { useEffect, useState } from 'react';
import axios from 'axios';

import formatCoinData from '../helpers/format-coin-data';
import { CoinData } from '../types';

export default function useCoinList(currentPage: number = 1, fetchAllCoins?: boolean) {
  const [error, setError] = useState(null);
  const [data, setData] = useState<CoinData[]>([]);
  const [check, setCheck] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      axios
        .get(
          `https://api.coingecko.com/api/v3/coins/markets?order=rank_desc&per_page=100&page=${currentPage}&vs_currency=usd&price_change_percentage=1h%2C24h%2C7d%2C14d%2C30d%2C200d%2C1y`
        )
        .then(res => setData(formatCoinData(res.data)))
        .catch(err => setError(err))
        .finally(() => setLoading(false));
    }

    async function getAllData() {
      axios
        .all(
          [1, 2, 3, 4].map(d =>
            axios.get(
              `https://api.coingecko.com/api/v3/coins/markets?order=rank_desc&per_page=100&page=${d}&vs_currency=usd&price_change_percentage=1h%2C24h%2C7d%2C14d%2C30d%2C200d%2C1y`
            )
          )
        )
        .then(
          axios.spread((a, b, c, d) => {
            const coins = a.data
              .concat(b.data)
              .concat(c.data)
              .concat(d.data);
            setData(formatCoinData(coins));
          })
        )
        .catch(err => setError(err))
        .finally(() => setLoading(false));
    }

    if (fetchAllCoins) getAllData();
    else getData();
  }, [currentPage, check]);

  return {
    data,
    error,
    loading,
    refetch: () => setCheck(Math.random()),
  };
}
