const intlFormatPercentage = num => new Intl.NumberFormat().format(Number(Number(num).toFixed(2)));

const intlFormat = num => (num <= 0 ? Number(num).toFixed(2)
  : num >= 1
    ? new Intl.NumberFormat().format(Number(Number(num).toFixed(2)))
    : Number(num).toFixed(6));

const formatNumber = num => {
  if (num >= 1000000000) return `${intlFormat(num / 1000000000)}B`;
  if (num >= 1000000) return `${intlFormat(num / 1000000)}M`;
  if (num >= 10000) return `${intlFormat(num / 1000)}k`;

  return intlFormat(num);
};

export { intlFormat, formatNumber, intlFormatPercentage };
