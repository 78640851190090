import styled from 'styled-components';

export const Content = styled.div`
  width: 560px;
  position: relative;
  border-radius: 6px;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  padding: 12px;
  width: 100%;
  background: #20232a;
`;

export const Form = styled.form`
  align-items: center;
  background: #000;
  display: flex;
  height: 56px;
  width: 100%;
  padding: 0 12px;
  border-radius: 4px;
  position: relative;
  box-shadow: inset 0 0 0 2px #4df2fa;
`;

export const MagnifierLabel = styled.label`
  align-items: center;
  color: #4df2fa;
  display: flex;
  justify-content: center;
`;

export const LoadingIndicator = styled.div`
  margin: 0;
  padding: 0;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: #f5f6f7;
  flex: 1;
  font: inherit;
  font-size: 1.2em;
  height: 100%;
  outline: none;
  padding: 0 0 0 8px;
  width: 80%;
`;

export const ResetButton = styled.button`
  -webkit-animation: fade-in 0.1s ease-in forwards;
  animation: fade-in 0.1s ease-in forwards;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  right: 0;
  stroke-width: 1.4;
`;

export const CancelButton = styled.button`
  appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: rgb(59, 59, 59);
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: rgba(73, 76, 106, 0.5);
  border-image: initial;
  display: none;
`;

export const Footer = styled.footer`
  width: 100%;
  z-index: 300;
  height: 44px;
  display: flex;
  flex-shrink: 0;
  padding: 0 12px;
  user-select: none;
  position: relative;
  align-items: center;
  background: #282c34;
  -ms-user-select: none;
  -webkit-user-select: none;
  border-radius: 0 0 8px 8px;
  flex-direction: row-reverse;
  justify-content: space-between;
  box-shadow: inset 0 1px 0 0 rgba(73, 76, 106, 0.5), 0 -4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const NoResultsFound = styled.p`
  color: #fff;
  margin: 16px;
  text-align: center;
`;

export const SocryptoLogo = styled.img`
  height: 12px;
`;

export const SearchResultContainer = styled.div`
  width: 100%;
  height: 50px;
`;

export const ResultsContainer = styled.div`
  max-height: 200px;
  overflow-y: scroll;
`;
