/* eslint-disable react/prop-types */
import React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ellipsize from '../../helpers/ellipsize';
import { intlFormat } from '../../helpers/format-number';

import star from './star.png';

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 1rem;
  border-radius: 2rem;
`;

const Percent = styled.span`
  color: ${({ val }) => (val >= 0 ? 'limegreen' : 'tomato')};
`;

const Text = styled.span`
  font-size: ${({ fontSize }) => fontSize || '16px'}
  color: ${({ color }) => color || '#F5F5F5'};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  :hover {
    span {
      color: dodgerblue;
    }
  }
`;

export default (url, watchlist, handleAddCoin, handleRemoveCoin, windowWidth) => [
  ...(watchlist
    ? [
        {
          Header: '',
          accessor: 'favourite',
          fixed: 'left',
          width: 40,
          Cell: ({ original }) =>
            watchlist.some(d => d.id === original.id) ? (
              <FlexCenter>
                <Avatar alt="star" src={star} onClick={() => handleRemoveCoin(original)} />
              </FlexCenter>
            ) : (
              <FlexCenter>
                <Avatar alt="star" src={star} onClick={() => handleAddCoin(original)} />
              </FlexCenter>
            ),
        },
      ]
    : []),
  {
    Header: '#',
    accessor: 'rank',
    fixed: 'left',
    width: 35,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ original }) => (
      <FlexCenter>
        <Avatar alt="avatar" src={original.avatar_url} />
        <Link to={{ pathname: `/assets/${original.id}` }}>
          <LinkWrapper>
            <Text color="#FFF" isBold>
              {original.code}
            </Text>
            <Text fontSize="12px" isBold>
              {windowWidth > 768 ? ellipsize(original.name, 16) : ellipsize(original.name, 8)}
            </Text>
          </LinkWrapper>
        </Link>
      </FlexCenter>
    ),
    fixed: 'left',
    width: windowWidth > 768 ? 150 : 110,
  },
  {
    Header: 'Price (USD)',
    accessor: 'price_usd',
    width: 200,
    Cell: ({ value, original }) => (
      <Link to={{ pathname: `/assets/${original.id}` }}>
        <LinkWrapper>
          <Text isBold color="#FFF">{`$${intlFormat(value)}`}</Text>
        </LinkWrapper>
      </Link>
    ),
  },
  {
    Header: 'Market Cap',
    accessor: 'market_cap_usd',
    width: 200,
    Cell: ({ value }) => <Text>{`$${intlFormat(value)}`}</Text>,
  },
  {
    Header: 'Circulating Supply',
    accessor: 'circulating_supply',
    width: 210,
    Cell: ({ value }) => <Text>{`$${intlFormat(value)}`}</Text>,
  },
  {
    Header: 'Volume (24h)',
    accessor: 'total_volume_usd',
    width: 150,
    Cell: ({ value }) => <Text>{`$${intlFormat(value)}`}</Text>,
  },
  {
    Header: 'Δ 1h',
    accessor: 'percent_change_1h',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
  {
    Header: 'Δ 24h',
    accessor: 'percent_change_24h',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
  {
    Header: 'Δ 7d',
    accessor: 'percent_change_7d',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
  {
    Header: 'Δ 14d',
    accessor: 'percent_change_14d',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
  {
    Header: 'Δ 30d',
    accessor: 'percent_change_30d',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
  {
    Header: 'Δ 200d',
    accessor: 'percent_change_200d',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
  {
    Header: 'Δ 1y',
    accessor: 'percent_change_1y',
    width: 70,
    Cell: ({ value }) => <Percent val={value}>{value}</Percent>,
  },
];
