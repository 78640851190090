import styled from 'styled-components';

export const Content = styled.div`
  margin-top: -1px;
`;

export const BarLine = styled.div`
  height: 5px;
  width: 100vw;
  background: linear-gradient(to right, #4df2fa, #1c64b6);
`;

export const AnimatedNavbarWrapper = styled.div`
  margin-bottom: -71px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const HamburgerWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 46px;
  position: fixed;
  z-index: 999999;
  @media (min-width: 768px) {
    display: none;
  }
`;
