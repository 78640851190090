import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(#000, #111);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;

  @media (max-width: 768px) {
    padding-top: 12vh;
  }

  @media (width: 480px) {
    min-height: auto;
  }
`;

export const SigmaLargeBlue = styled.img`
  width: 30rem;

  @media (max-width: 768px) {
    width: 60vw;
  }

  @media (max-width: 480px) {
    width: 85vw;
  }
`;
