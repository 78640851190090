import { CoinData, CoinGeckoCoin } from '../types';

function formatCoin(coin: CoinGeckoCoin): CoinData {
  return {
    avatar_url: coin.image,
    circulating_supply: coin?.circulating_supply?.toString(),
    code: coin.symbol?.toUpperCase(),
    id: coin.id,
    last_updated: coin.last_updated,
    market_cap_btc: coin.market_cap,
    market_cap_usd: coin.market_cap,
    max_supply: coin.max_supply,
    name: coin.name,
    rank: coin.market_cap_rank,
    total_volume_usd: coin.total_volume,
    percent_change_1h: coin.price_change_percentage_1h_in_currency?.toFixed(2),
    percent_change_1y: coin.price_change_percentage_1y_in_currency?.toFixed(2),
    percent_change_7d: coin.price_change_percentage_7d_in_currency?.toFixed(2),
    percent_change_14d: coin.price_change_percentage_14d_in_currency?.toFixed(2),
    percent_change_24h: coin.price_change_percentage_24h_in_currency?.toFixed(2),
    percent_change_30d: coin.price_change_percentage_30d_in_currency?.toFixed(2),
    percent_change_200d: coin.price_change_percentage_200d_in_currency?.toFixed(2),
    price_btc: coin.current_price,
    price_usd: coin.current_price,
  };
}

function formatCoinData(data: CoinGeckoCoin[]) {
  return data.map(formatCoin);
}

export default formatCoinData;
