import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 50px;
  margin-right: 30px;
`;
