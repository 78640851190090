import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Container, Logo } from './styles';
import AnimatedList from '../../components/AnimatedList';
import EmailBanner from '../../components/EmailBanner';

import useInterval from '../../hooks/interval';
import showToast from '../../helpers/show-toast';
import formatCoinData from '../../helpers/format-coin-data';
import SocryptoLogo from '../../assets/socrypto/socrypto-large-white.svg';
import { CoinData } from '../../types';

function useCoinList(url: string) {
  const [coinList, setCoinList] = useState<CoinData[]>([]);
  const [check, setCheck] = useState('fetch');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function fetchData() {
      if (check === 'fetch') {
        axios
          .get(url)
          .then(({ data }) => {
            const formatted = formatCoinData(data);
            setCoinList(formatted);
          })
          .catch(e => {
            showToast('Unable to connect (Rate limit exceeded)');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    fetchData();
  }, [check]);

  return { coinList, loading, refetch: (arg: string) => setCheck(arg) };
}

function Home() {
  const { coinList, loading, refetch } = useCoinList(
    'https://api.coingecko.com/api/v3/coins/markets?order=rank_desc&per_page=10&page=1&vs_currency=usd&price_change_percentage=1h%2C24h%2C7d%2C14d%2C30d%2C200d%2C1y'
  );

  useInterval(() => refetch('fetch'), 22500);

  const [focused, setFocused] = useState(null); // Expanding list item that is focused

  return (
    <>
      <Container height="50vh">
        <Logo src={SocryptoLogo} />
      </Container>

      <AnimatedList data={coinList} loaded={!loading} focused={focused} setFocused={setFocused} />

      <EmailBanner />
    </>
  );
}

export default Home;
