import styled from 'styled-components';

type ButtonBannerProps = {
  isSingleElement: boolean;
};

export const ButtonBanner = styled.div<ButtonBannerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: ${({ isSingleElement }) => (isSingleElement ? 'flex-end' : 'space-between')};
  }
`;

export const Heading = styled.h3`
  font-size: 48px;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.17;
  color: rgba(255, 255, 255, 0.85);

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const Subheading = styled.h5`
  margin: 0;
  font-size: 26px;
  font-weight: bold;
  color: rgba(255, 255, 255);

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const Live = styled.img`
  height: 50px;
  margin-top: 2rem;
  margin-bottom: -2rem;

  @media (max-width: 480px) {
    margin: 0;
  }
`;

export const TopButtonsWrapper = styled.div`
  display: flex;
  margin-top: -46px;
  margin-right: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
`;

export const BottomButtonsWrapper = styled.div`
  margin-bottom: -36px;
  margin-right: 10px;
  padding-top: 10px;
`;
