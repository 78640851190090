import React, { FC } from 'react';
import Select from 'react-select';

type SelectButtonOptions = {
  options: any;
  onChange: any;
  loading: boolean;
};

const SelectButton: FC<SelectButtonOptions> = ({ options, loading, onChange }) => (
  <Select
    placeholder="Search..."
    value={null}
    options={options}
    onChange={onChange}
    isLoading={loading}
    theme={theme => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary: 'black',
      },
    })}
  />
);

export default SelectButton;
