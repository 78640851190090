import React, { useState, useEffect } from 'react';
import { stack as Menu } from 'react-burger-menu';

import { Title, SigmaImg, FlexCenter, ProductName, CustomLink, SocryptoLogo } from './styles';
import SigmaSmallBlue from '../../assets/sigma/sigma-small-blue.png';
import SocryptoTextBlack from '../../assets/socrypto/socrypto-text-black.svg';

function HamburgerNavbar({ history }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // If the hamburger menu is open, disable scrolling on the site
    // overflow: hidden disables the scrolling on a desktop browser
    if (isOpen) document.body.setAttribute('style', 'overflow: hidden;');
    else document.body.setAttribute('style', 'overflow: visible;');
  }, [isOpen]);

  function handleStateChange(s) {
    setIsOpen(s.isOpen);
  }

  function handleLinkClick(url) {
    setIsOpen(false);
    history.push(url);
  }

  return (
    <Menu right width="100%" isOpen={isOpen} disableAutoFocus onStateChange={state => handleStateChange(state)}>
      <CustomLink tabIndex={0} role="button" onKeyDown={() => handleLinkClick('/')} onClick={() => handleLinkClick('/')}>
        <SocryptoLogo src={SocryptoTextBlack} />
      </CustomLink>
      <Title>Products</Title>
      <CustomLink
        tabIndex={0}
        role="button"
        onKeyDown={() => handleLinkClick('/sigma')}
        onClick={() => handleLinkClick('/sigma')}
      >
        <FlexCenter>
          <SigmaImg src={SigmaSmallBlue} />
          <ProductName>Sigma</ProductName>
        </FlexCenter>
      </CustomLink>
      {/*
          <CustomLink tabIndex={0}
        role='button'
        onKeyDown={() => handleLinkClick('/etherlearn')}
        onClick={() => handleLinkClick('/etherlearn')}
      >
        <FlexCenter>
          <SigmaImg src={SigmaSmallBlue} />
          <ProductName>EtherLearn</ProductName>
        </FlexCenter>
      </CustomLink> */}

      <Title>Market</Title>
      <CustomLink
        tabIndex={0}
        role="button"
        onKeyDown={() => handleLinkClick('/assets')}
        onClick={() => handleLinkClick('/assets')}
      >
        <FlexCenter>
          <ProductName>Cryptocurrencies</ProductName>
        </FlexCenter>
      </CustomLink>

      <CustomLink
        tabIndex={0}
        role="button"
        onKeyDown={() => handleLinkClick('/watchlist')}
        onClick={() => handleLinkClick('/watchlist')}
      >
        <FlexCenter>
          <ProductName>Watchlist</ProductName>
        </FlexCenter>
      </CustomLink>

      <Title>API</Title>
      <CustomLink tabIndex={0} role="button" onKeyDown={() => handleLinkClick('/api')} onClick={() => handleLinkClick('/api')}>
        <FlexCenter>
          <ProductName>Documentation</ProductName>
        </FlexCenter>
      </CustomLink>
      <CustomLink tabIndex={0} role="button" onKeyDown={() => handleLinkClick('/api')} onClick={() => handleLinkClick('/api')}>
        <FlexCenter>
          <ProductName>Limits</ProductName>
        </FlexCenter>
      </CustomLink>

      <hr style={{ borderTop: '1px solid #f5f5f5' }} />

      {/*
          <CustomLink tabIndex={0}
        role='button'
        onKeyDown={() => handleLinkClick('/watchlist')}
        onClick={() => handleLinkClick('/watchlist')}
      >
        <FlexCenter>
          <ProductName>Watch List</ProductName>
        </FlexCenter>
      </CustomLink> */}

      <CustomLink tabIndex={0} role="button" onKeyDown={() => handleLinkClick('/api')} onClick={() => handleLinkClick('/api')}>
        <FlexCenter>
          <ProductName>API</ProductName>
        </FlexCenter>
      </CustomLink>

      <CustomLink
        tabIndex={0}
        role="button"
        onKeyDown={() => handleLinkClick('/newsletter')}
        onClick={() => handleLinkClick('/newsletter')}
      >
        <FlexCenter>
          <ProductName>Newsletter</ProductName>
        </FlexCenter>
      </CustomLink>
    </Menu>
  );
}

export default HamburgerNavbar;
