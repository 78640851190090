import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  padding: 4rem;
  padding-bottom: 2rem;
  background-color: #111;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

export const Section = styled.div`
  margin: 0 auto;
`;

export const Logos = styled.div`
  display: flex;
  white-space: nowrap;

  @media (max-width: 480px) {
    justify-content: space-between;
  }
`;

export const Title = styled.h1`
  margin-bottom: 1.875rem;
  font-size: 2.25rem;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 1.65rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

export const Kicker = styled.h3`
  color: #f5f5f5;
  margin-bottom: 1.875rem;
`;

export const CheckList = styled.ul``;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Check = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 1.5rem;
`;

export const Text = styled.p`
  font-size: 1.25rem;
  color: #f5f5f5;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.95rem;
  }
`;

export const AndroidImg = styled.img`
  margin-right: 1.5rem;

  @media (max-width: 480px) {
    margin-right: 0.5rem;
  }
`;

export const ScreenShot = styled.img`
  width: 24rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 80vw;
  }

  @media (max-width: 480px) {
    width: 90vw;
  }
`;
