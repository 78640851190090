import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(150deg, #2980b9 15%, #2c3e50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
`;

export const Heading = styled.h3`
  font-size: 48px;
  font-weight: 600;
  line-height: 1.17;
  color: rgba(255, 255, 255, 0.5);
`;
