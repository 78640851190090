import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(150deg, #2BA8D4 15%, #9446F7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 55vh;
`;

export const Heading = styled.h3`
  font-size: 48px;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.17;
  color: rgba(255, 255, 255, 0.85);

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

export const Subheading = styled.h5`
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  color: rgba(255, 255, 255);
  text-shadow: 0px 0px 1px #FFF,0px 0px 1px #FFF,0px 0px 1px #FFF,0px 0px 10px #4499D8,0px 0px 10px #4499D8;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Feature = styled.div`
  margin: 4rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 2rem;
  }
`;

export const Image = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const Title = styled.h3`
  font-size: 1.6rem;
`;

export const Text = styled.p`
  font-size: 1rem;
`;
