import React from 'react';

import DataIcon from '../../assets/api/data.svg';
import ChartIcon from '../../assets/api/chart.svg';
import NewsIcon from '../../assets/api/news.svg';

import {
  Container,
  Heading,
  Subheading,
  Features,
  Feature,
  Image,
  Title,
  Text,
} from './styles';

function API() {
  return (
    <>
      <Container>
        <Heading>Professional API Plans</Heading>
        <Subheading>Coming soon.</Subheading>
      </Container>
      <Features>
        <Feature>
          <Image src={DataIcon} />
          <Title>Historical Data</Title>
          <Text>Historical data from any coin and exchange per minute, hour and day.</Text>
        </Feature>
        <Feature>
          <Image src={ChartIcon} />
          <Title>Price & Market</Title>
          <Text>Get the live prices and other information from more than 3000 coins.</Text>
        </Feature>
        <Feature>
          <Image src={NewsIcon} />
          <Title>News</Title>
          <Text>Get all the latest news from our multiple official news sources.</Text>
        </Feature>
      </Features>
    </>
  );
}

export default API;
