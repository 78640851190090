import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown/with-html';
import articles from '../../../data/articles';
import { titleCase } from '../../../helpers/text-helpers';
import useDocumentTitle from '../../../hooks/document-title';
import {
  Container,
  Heading,
  Subheading,
  Image,
  MarkDownWrapper,
} from '../styles';
import StudentsCap from '../../../assets/academy/students-cap.svg';

function Article(props) {
  const { subheading, match, location } = props;
  const { article } = match.params;
  const title = titleCase(article.replace(/-/g, ' '));

  useDocumentTitle(title, location.pathname);

  return (
    <>
      <Container>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Heading>{title}</Heading>
          <Image src={StudentsCap} />
        </div>
        <Subheading>{subheading}</Subheading>
      </Container>
      <MarkDownWrapper>
        <ReactMarkdown
          source={articles[article.replace(/-/g, '').toLowerCase()]}
          escapeHtml={false}
        />
      </MarkDownWrapper>
    </>
  );
}

Article.propTypes = {
  subheading: PropTypes.string.isRequired,
};

export default Article;
