import React, { useEffect } from 'react';

import Landing from './Landing';
import CoinGrid from './CoinGrid';
import Download from './Download';

import showToast from '../../helpers/show-toast';
import { Container, SigmaLargeBlue } from './styles';
import SigmaLogo from '../../assets/sigma/sigma-large-blue.svg';

function Sigma() {
  function showMessage() {
    showToast('Application no longer available. This page remains for historical purposes.', 15000);
  }

  useEffect(() => {
    showMessage();
  }, []);

  return (
    <>
      <Container>
        <SigmaLargeBlue src={SigmaLogo} />
      </Container>
      <Landing showMessage={showMessage} />
      <CoinGrid />
      <Download showMessage={showMessage} />
    </>
  );
}

export default Sigma;
