import React, { FC } from 'react';

import { StyledButton } from './styles';

type ButtonProps = {
  title: string;
  onClick(): void;
  background?: string;
};

const Button: FC<ButtonProps> = ({ title, onClick, background = '#000' }) => {
  function handleClick() {
    window.scrollTo(0, 0);
    onClick();
  }
  return (
    <StyledButton background={background} onClick={handleClick} type="button">
      {title}
    </StyledButton>
  );
};

export default Button;
